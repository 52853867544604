// src/utils/randomSort.js

/**
 * Randomly sorts the input array.
 *
 * @param {Array} array - The array to sort.
 * @returns {Array} - The randomly sorted array.
 */
const randomSort = (array) => {
  let copy = array.slice();

  // Fisher-Yates shuffle
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copy[i], copy[j]] = [copy[j], copy[i]];
  }

  return copy;
};

export default randomSort;
