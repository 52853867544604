// src/TermsOfService.js
import { Card, Flex } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const TermsOfService = () => {
  const { i18n } = useTranslation();
  const [markdown, setMarkdown] = useState('');

  const markdownFile =
    i18n.language === 'ja'
      ? '/terms-of-service.ja.md'
      : '/terms-of-service.en.md';

  useEffect(() => {
    // eslint-disable-next-line
    fetch(markdownFile)
      .then((response) => response.text())
      .then((text) => setMarkdown(text))
      .catch((error) =>
        console.error('Error loading the markdown file:', error)
      );
  }, [i18n.language, markdownFile]);

  return (
    <Flex direction='column' width='100%'>
      <Card>
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </Card>
    </Flex>
  );
};

export default TermsOfService;
