import { SignInButtonOptions, SignInButtonText } from "../../types";
import { getGreenWhiteSvg, getGreenBlueSvg } from "./logos";

const getButtonText = (text?: SignInButtonText) => {
  switch (text) {
    case "signin":
      return "Sign in";
    case "continue_with":
      return "Continue with OpenPass";
    default:
      return "Sign in with OpenPass";
  }
};

export const createSignInButton = (options: SignInButtonOptions): HTMLButtonElement => {
  const text = options.text ?? "signin_with";
  const size = options.size ?? "large";
  const theme = options.theme ?? "openpass";
  const shape = options.shape ?? "standard";
  const shapeVariant = options.shapeVariant ?? "pill";

  const button = document.createElement("button");
  button.setAttribute("type", "button");
  button.setAttribute("aria-label", "Sign in with OpenPass Button");
  button.classList.add("op-js-sdk-siwopb__root");
  button.classList.add(`op-js-sdk-siwopb__size-shape-${shape}-${size}`);
  button.classList.add(`op-js-sdk-siwopb__size-shape-${shape}-${shapeVariant}-${size}`);
  button.classList.add(`op-js-sdk-siwopb__theme-${theme}`);

  const innerDiv = document.createElement("div");
  button.appendChild(innerDiv);

  if (options.additionalWidth && options.additionalWidth > 0) {
    const halfAdditionalWidth = options.additionalWidth / 2;
    innerDiv.style.marginLeft = `${halfAdditionalWidth}px`;
    innerDiv.style.marginRight = `${halfAdditionalWidth}px`;
  }

  const logoElement = theme === "light" ? getGreenBlueSvg(size) : getGreenWhiteSvg(size);
  logoElement.classList.add(`op-js-sdk-siwopb__logo-${size}`);
  logoElement.classList.add(`op-js-sdk-siwopb__logo-shape-${shape}-${size}`);
  innerDiv.appendChild(logoElement);

  if (shape === "standard") {
    const span = document.createElement("span");
    span.innerText = getButtonText(text);
    innerDiv.appendChild(span);
  }

  return button;
};
