export const SDK_NAME = "openpass-js-sdk";

export const HEADER_SDK_NAME = "OpenPass-SDK-Name";
export const HEADER_SDK_VERSION = "OpenPass-SDK-Version";

export const PARAM_SDK_NAME = "sdk_name";
export const PARAM_SDK_VERSION = "sdk_version";

export const PARAM_STATE = "state";
export const PARAM_REDIRECT_URI = "redirect_uri";
export const PARAM_RESPONSE_TYPE = "response_type";
export const PARAM_CLIENT_ID = "client_id";
export const PARAM_CODE_CHALLENGE = "code_challenge";
export const PARAM_CODE_CHALLENGE_METHOD = "code_challenge_method";
export const PARAM_CODE_CHALLENGE_METHOD_VALUE = "S256";
export const PARAM_RESPONSE_TYPE_VALUE = "code";
export const PARAM_SCOPE = "scope";
export const PARAM_SCOPE_VALUE = "openid";
export const PARAM_CODE = "code";
export const PARAM_GRANT_TYPE_VALUE = "authorization_code";
export const PARAM_ERROR = "error";
export const PARAM_ERROR_DESCRIPTION = "error_description";
export const PARAM_ERROR_URI = "error_uri";
export const PARAM_CODE_RESPONSE_MODE = "response_mode";
export const PARAM_CODE_RESPONSE_MODE_MESSAGE = "post_message";
export const PARAM_SIGN_IN_SOURCE = "op_auth_session_source";
export const POPUP_MESSAGE_SOURCE = "openpass-authorize-message";
export const PARAM_CODE_LOGIN_HINT = "login_hint";
export const PARAM_CODE_CONSENT_TOKEN_JWT = "consent_jwt";
export const PARAM_CODE_DISABLE_LOGIN_HINT_EDITING = "disable_login_hint_editing";
export const PARAM_CODE_INLINE_SIGN_IN_FORM_SIGN_IN_BUTTON_TEXT = "submit_button_text";
export const PARAM_CODE_INLINE_SIGN_IN_FORM_SIGN_IN_BUTTON_BACKGROUND_HEX_COLOUR = "sign_in_button_background_hex_colour";
export const PARAM_CODE_INLINE_SIGN_IN_FORM_HIDE_CLIENT_APPLICATION_LOGO = "hide_client_application_logo";
export const PARAM_CODE_INLINE_SIGN_IN_FORM_HIDE_HEADER_TEXT = "hide_header_text";
