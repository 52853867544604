import { getOpenPassApiBaseUrl } from "../config";
import { InlineSignInOptions, OpenPassOptions, SignInOptionsBase } from "../types";
import { OpenPassApiClient } from "./api/openPassApiClient";
import {
  PARAM_CODE_INLINE_SIGN_IN_FORM_HIDE_CLIENT_APPLICATION_LOGO,
  PARAM_CODE_INLINE_SIGN_IN_FORM_HIDE_HEADER_TEXT,
  PARAM_CODE_INLINE_SIGN_IN_FORM_SIGN_IN_BUTTON_BACKGROUND_HEX_COLOUR,
  PARAM_CODE_INLINE_SIGN_IN_FORM_SIGN_IN_BUTTON_TEXT,
} from "./constants";
import { SdkError } from "./error/errors";
import PopupAuth from "./popup";
import RedirectAuth from "./redirect";
import { matchesEventOrigin } from "./url";

/**
 * Handles rendering and functionality of an inline sign-in form.
 */
export default class InlineSignInForm {
  private readonly redirectAuth: RedirectAuth;
  private readonly popupAuth: PopupAuth;
  private readonly openPassOptions: OpenPassOptions;
  private readonly apiClient: OpenPassApiClient;

  private currentLoginHint?: string;

  /**
   * Initializes a new instance of the InlineSignInForm class.
   * @param openPassOptions - The OpenPass options.
   * @param redirectAuth - The redirect authentication object.
   * @param popupAuth - The popup authentication object.
   */
  constructor(openPassOptions: OpenPassOptions, redirectAuth: RedirectAuth, popupAuth: PopupAuth, apiClient: OpenPassApiClient) {
    this.openPassOptions = openPassOptions;
    this.popupAuth = popupAuth;
    this.redirectAuth = redirectAuth;
    this.apiClient = apiClient;
  }

  /**
   * Renders the inline sign-in form with the specified options.
   * @param inlineSignInOptions - The inline sign-in options.
   * @throws {SdkError} If the required options are missing.
   */
  public renderInlineSignInForm(inlineSignInOptions: InlineSignInOptions): void {
    if (!inlineSignInOptions.parentContainerElementId) {
      throw new SdkError("inlineSignInOptions.parentContainerElementId is required for inline sign-in method.");
    }

    inlineSignInOptions.signinButtonTextOption = inlineSignInOptions.signinButtonTextOption ?? "continue";

    const iframeContainerHtmlElement = document.getElementById(inlineSignInOptions.parentContainerElementId);
    const authenticationMode = inlineSignInOptions.authenticationMode;

    if (!iframeContainerHtmlElement) {
      throw new SdkError(
        `Cannot locate parent container element "${inlineSignInOptions.parentContainerElementId}" for inline sign-in form.`
      );
    }

    if (authenticationMode != "popup" && authenticationMode != "redirect") {
      throw new SdkError(`Invalid authentication mode: ${authenticationMode}.`);
    }

    if (authenticationMode == "popup" && !inlineSignInOptions.popupSuccessCallback) {
      throw new SdkError("Must provide popupSuccessCallback for inline sign-in form when authentication mode is popup.");
    }

    if (inlineSignInOptions.widthInPixels && inlineSignInOptions.widthInPixels < 250) {
      throw new SdkError("Inline sign-in form width must be at least 250 pixels.");
    }

    if (inlineSignInOptions.heightInPixels && inlineSignInOptions.heightInPixels < 500) {
      throw new SdkError("Inline sign-in form width must be at least 500 pixels.");
    }

    const popupSuccessCallback = inlineSignInOptions.popupSuccessCallback;
    const popupFailureCallback = inlineSignInOptions.popupFailureCallback;

    const signInMessageHandler = async (event: MessageEvent) => {
      //Check that the message is correct and came from the OpenPass API inline sign in form
      if (
        !matchesEventOrigin(event.origin, getOpenPassApiBaseUrl(this.openPassOptions.baseUrl)) ||
        !event.data ||
        event.data.type != "inline-sign-in-message"
      ) {
        return;
      }

      const { data } = event;

      const signInOptionsBase: SignInOptionsBase = {
        clientState: inlineSignInOptions.clientState,
        disableLoginHintEditing: false,
        loginHint: data.loginHint,
        consentJwt: data.consentJwt,
      };

      switch (authenticationMode) {
        case "popup":
          try {
            // refocus popup if the login hints match and the popup is already open
            if (this.currentLoginHint === data.loginHint && this.popupAuth.refocusIfPopupExists()) {
              break;
            }

            // otherwise start a new flow
            this.currentLoginHint = data.loginHint;
            const popupResult = await this.popupAuth.signInWithPopup({
              ...signInOptionsBase,
              redirectUrl: inlineSignInOptions.redirectUrl,
              source: "SignInWithOpenPassInlineForm",
            });
            if (popupSuccessCallback) {
              popupSuccessCallback(popupResult);
            }
          } catch (error) {
            if (error instanceof SdkError) {
              if (popupFailureCallback) {
                popupFailureCallback(error);
              }
            } else {
              console.error(error);
            }
          }
          break;

        case "redirect":
          this.redirectAuth.signIn({
            ...signInOptionsBase,
            redirectUrl: inlineSignInOptions.redirectUrl,
            source: "SignInWithOpenPassInlineForm",
          });
          break;
        default:
          console.log("Invalid authentication mode: " + authenticationMode);
          break;
      }
    };

    window.addEventListener("message", signInMessageHandler);

    const iframe = this.createIframeElement(this.openPassOptions.clientId, inlineSignInOptions);
    iframeContainerHtmlElement.appendChild(iframe);

    // Send telemetry event
    // Do not await the result, this is a fire and forget operation
    this.apiClient.sendTelemetryEvent("SignInWithOpenPassInlineFormShown");
  }

  private createIframeElement(clientId: string, inlineSignInOptions: InlineSignInOptions) {
    const inlineSignInIFrame = document.createElement("iframe") as HTMLIFrameElement;

    const openpassBaseUrl = getOpenPassApiBaseUrl(this.openPassOptions.baseUrl);

    const inlineSignInFrameUrl = new URL(`/inline-sign-in`, openpassBaseUrl);
    inlineSignInFrameUrl.searchParams.append("client_id", clientId);
    inlineSignInFrameUrl.searchParams.append(
      PARAM_CODE_INLINE_SIGN_IN_FORM_SIGN_IN_BUTTON_TEXT,
      inlineSignInOptions.signinButtonTextOption?.toString() ?? "continue"
    );

    if (inlineSignInOptions.hideSignInFormApplicationLogo) {
      inlineSignInFrameUrl.searchParams.append(
        PARAM_CODE_INLINE_SIGN_IN_FORM_HIDE_CLIENT_APPLICATION_LOGO,
        inlineSignInOptions.hideSignInFormApplicationLogo.toString()
      );
    }
    if (inlineSignInOptions.hideSignInFormHeaderText) {
      inlineSignInFrameUrl.searchParams.append(
        PARAM_CODE_INLINE_SIGN_IN_FORM_HIDE_HEADER_TEXT,
        inlineSignInOptions.hideSignInFormHeaderText.toString()
      );
    }
    if (inlineSignInOptions.signinButtonBackgroundColorHex) {
      inlineSignInFrameUrl.searchParams.append(
        PARAM_CODE_INLINE_SIGN_IN_FORM_SIGN_IN_BUTTON_BACKGROUND_HEX_COLOUR,
        inlineSignInOptions.signinButtonBackgroundColorHex
      );
    }

    inlineSignInIFrame.src = inlineSignInFrameUrl.toString();
    inlineSignInIFrame.width = inlineSignInOptions.widthInPixels?.toString() ?? "100%";
    inlineSignInIFrame.height = inlineSignInOptions.heightInPixels?.toString() ?? "100%";

    return inlineSignInIFrame;
  }
}
