// src/components/LanguageSelector.js
import { Button } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const languages = ['en', 'ja'];

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      {languages.map((lng) => (
        <Button
          size='small'
          onClick={() => handleChangeLanguage(lng)}
          key={lng}
          aria-label={`Switch to ${lng}`}
        >
          {lng}
        </Button>
      ))}
    </>
  );
};

export default LanguageSelector;
