import * as consts from "./auth/constants";

export const config = {
  SSO_BASE_URL: "https://auth.myopenpass.com", //SSO base uri
  SSO_AUTHORIZE_PATH: "/v1/api/authorize", //SSO authorize endpoint
  SSO_TOKEN_PATH: "/v1/api/token", //SSO token endpoint
  SSO_AUTHORIZE_DEVICE_PATH: "/v1/api/authorize-device", //SSO authorize device endpoint
  SSO_DEVICE_TOKEN_PATH: "/v1/api/device-token", //SSO device token endpoint
  SSO_TELEMETRY_EVENT_PATH: "/v1/api/telemetry/event", //SSO client telemetry event endpoint
  SSO_STORAGE_PREFIX: "__openpass", //prefix for storing a value in storage
  API_DEFAULT_TIMEOUT_MS: 60 * 1000, //default timeout for making API requests
  POPUP_RESPONSE_TIMEOUT_MS: 10 * 60 * 1000, //the timeout to receive a response from the popup
};

export const getOpenPassApiBaseUrl = (baseUrl: string | undefined | null): string => {
  return baseUrl || config.SSO_BASE_URL;
};

export const getApiDefaultTimeoutMs = () => {
  return config.API_DEFAULT_TIMEOUT_MS;
};

export const getParamGrantTypeValue = () => {
  return consts.PARAM_GRANT_TYPE_VALUE;
};
