// src/components/FileDropZone.js
import { DropZone, Flex, Text } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../utils';

const FileDropZone = ({ onDrop, files }) => {
  const { t } = useTranslation();

  return (
    <>
      <DropZone
        onDropComplete={onDrop}
        accept='.csv'
        aria-label={t('dropzone')}
      >
        {t('dropzone')}
      </DropZone>
      {files.map((file, index) => (
        <Flex key={index} justifyContent='space-between'>
          <Text>{file.name}</Text>
          <Text>{formatValue(file.size)} bytes.</Text>
        </Flex>
      ))}
    </>
  );
};

export default FileDropZone;
