// src/OpenPassRedirect.js
import { Card, Flex, Text } from '@aws-amplify/ui-react';
import { OpenPassClient } from '@openpass/openpass-js-sdk';
import React, { useEffect, useState } from 'react';

const clientId = '6c9f3ac4ecd246e8b84674b42024ee0a';
const redirectURI = 'https://dii-encoder.pier1.co.jp/op_callback';

const OpenPassRedirect = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const openPassClient = new OpenPassClient({
      clientId: clientId,
    });

    if (!email) {
      openPassClient.renderSignInButton({
        parentContainerElementId: 'sign-in',
        authenticationMode: 'redirect',
        redirectUrl: redirectURI,
        text: 'signin_with',
      });
    }

    const handleRedirect = async () => {
      if (openPassClient.isAuthenticationRedirect()) {
        try {
          const signInResponse = await openPassClient.handleAuthenticationRedirect();
          setEmail(signInResponse.idToken.email);
          console.log(signInResponse.idToken);
          console.log(signInResponse.clientState);
        } catch (e) {
          console.error('Error handling authentication redirect:', e);
        }
      }
    };

    handleRedirect();
  }, [email]);

  return (
    <Flex direction='column' width='100%'>
      {email ? (
        <Text>Logged in with email: {email}</Text>
      ) : (
        <Card id='sign-in' />
      )}
    </Flex>
  );
};

export default OpenPassRedirect;
