export const windowOpenPopup = (url: string, name: string, width: number, height: number) => {
  const winLeft = (window.outerWidth - width) / 2 + window.screenX;
  const winTop = (window.outerHeight - height) / 2 + window.screenY;

  const appearance = `scrollbars=yes,resizable=yes,toolbar=no,top=${winTop},left=${winLeft},width=${width},height=${height}`;
  return window.open(url, name, appearance);
};

export const windowOpenPopupWithPercentage = (url: string, name: string, sizePercentage: number) => {
  if (sizePercentage === 100) {
    const appearance = `scrollbars=yes,resizable=yes,toolbar=no,top=${window.screenY},left=${window.screenX},width=${window.outerWidth},height=${window.outerHeight}`;
    return window.open(url, name, appearance);
  }

  const height = calculatePercentage(window.innerWidth, sizePercentage);
  const width = calculatePercentage(window.innerWidth, sizePercentage);

  return windowOpenPopup(url, name, width, height);
};

export const isLargeBreakpoint = () => {
  return window.matchMedia("screen and (min-width:960px)").matches;
};

const calculatePercentage = (amount: number, percent: number) => {
  return (amount * percent) / 100;
};
