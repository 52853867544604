// src/App.js
import {
  Flex,
  ThemeProvider,
  useBreakpointValue,
  useTheme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import i18n from 'i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Footer, Header, LanguageSelector } from './components';
import Home from './Home';
import Inquiry from './Inquiry';
import enTranslations from './locales/en.json';
import jaTranslations from './locales/ja.json';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import OpenPassCallback from './OpenPassCallback';
import OpenPassPopup from './OpenPassPopup';
import OpenPassRedirect from './OpenPassRedirect';

// i18n configuration
i18n
  .use(BrowserLanguageDetector, {
    fallback: 'en',
    order: ['navigator'],
  })
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      ja: { translation: jaTranslations },
    },
    interpolation: {
      escapeValue: false,
    },
  });

const App = () => {
  const { tokens } = useTheme();
  const { t } = useTranslation();

  return (
    <ThemeProvider>
      <Flex
        direction='column'
        minHeight='100vh'
        width={useBreakpointValue({
          small: '480px',
          medium: '768px',
          large: '800px',
        })}
        backgroundColor={tokens.colors.background.primary}
      >
        <Header title={t('title')} />

        <Flex justifyContent='flex-end'>
          <LanguageSelector />
        </Flex>

        <Flex flex='1'>
          <Routes>
            <Route path='/inquiry' element={<Inquiry />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-of-service' element={<TermsOfService />} />
            <Route path='/openpass-popup' element={<OpenPassPopup />} />
            <Route path='/openpass-redirect' element={<OpenPassRedirect />} />
            <Route path='/op_callback' element={<OpenPassCallback />} />
            <Route path='/*' element={<Home />} />
          </Routes>
        </Flex>

        <Footer />
      </Flex>
    </ThemeProvider>
  );
};

export default App;
