// src/utils/encodeFile.js
import CryptoJS from 'crypto-js';
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import validator from 'validator';

/**
 * Check if a string is a valid email address.
 *
 * @param {string} email - The email address to check.
 * @returns {boolean} True if the email is valid, false otherwise.
 */
const isValidEmail = (email) => {
  return validator.isEmail(email);
};

/**
 * Normalize an email address.
 *
 * @param {string} email - The email address to normalize.
 * @returns {string} The normalized email address.
 */
const normalizeEmailString = (email) => {
  // Remove leading and trailing spaces and convert to lowercase.
  email = email.trim().toLowerCase();

  // In gmail.com addresses only.
  if (email.endsWith('@gmail.com')) {
    let [local, domain] = email.split('@');
    // Remove all dots.
    local = local.replace(/\./g, '');
    // Remove everything after the first plus sign.
    local = local.split('+')[0];
    email = local + '@' + domain;
  }
  return email;
};

/**
 * Normalize a phone number.
 *
 * @param {string} phone - The phone number to normalize.
 * @param {string} regionCode - The region code of the phone number.
 * @returns {string} The normalized phone number.
 */
const normalizePhoneNumber = (phone, regionCode) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(phone, regionCode);
    if (phoneNumber) {
      return phoneNumber.format('E.164');
    }
  } catch (error) {
    console.error('normalizePhoneNumber failed', error.message);
  }
  return '';
};

/**
 * Base64 encode a string.
 *
 * @param {CryptoJS.lib.WordArray} data - The data to encode.
 * @returns {string} The base64 encoded string.
 */
const base64Encode = (data) => {
  return CryptoJS.enc.Base64.stringify(data);
};

/**
 * Hash a string using the SHA-256 algorithm.
 *
 * @param {string} data - The data to hash.
 * @returns {CryptoJS.lib.WordArray} The hashed data.
 */
const hashSHA256 = (data) => {
  return CryptoJS.SHA256(data);
};

/**
 * Encode a file.
 *
 * @param {File} file - The file to encode.
 * @param {string} fileType - The type of data in the file.
 * @param {string} countryCode - The country code for phone numbers.
 * @returns {Promise} A promise that resolves with the encoded data.
 * @resolves {Object} An object containing the encoded data.
 * @rejects {Error} An error object.
 */
const encodeFile = (file, fileType, countryCode) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const lines = [];

    reader.onload = (event) => {
      const fileContent = event.target.result;
      const fileLines = fileContent.split('\n');

      fileLines.forEach((line) => {
        if (fileType === 'email' && isValidEmail(line)) {
          const normalized = normalizeEmailString(line);
          const hashed = hashSHA256(normalized);
          const encoded = base64Encode(hashed);
          lines.push(encoded);
        } else if (
          fileType === 'phone' &&
          isValidPhoneNumber(line, countryCode)
        ) {
          const normalized = normalizePhoneNumber(line, countryCode);
          const hashed = hashSHA256(normalized);
          const encoded = base64Encode(hashed);
          lines.push(encoded);
        }
      });

      resolve({
        name: file.name,
        size: file.size,
        content: lines,
      });
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(file);
  });
};

export default encodeFile;
