import { SignInState } from "./internalTypes";
import { config } from "../config";

const STORAGE_KEY = `${config.SSO_STORAGE_PREFIX}__client_session`;

/**
 * Interface that represents a storage abstraction
 */
export interface SignInStateStorageProvider {
  get: (key: string, defaultValue: string) => string;
  set: (key: string, value: string) => void;
  remove: (key: string) => void;
}

/**
 * Class that manages the state during a sign-in / authentication attempt.
 */
export class SignInStateRepository {
  private signInStateStorage: SignInStateStorageProvider;

  public constructor(signInStateStorage: SignInStateStorageProvider) {
    this.signInStateStorage = signInStateStorage;
  }

  public add(signInState: SignInState) {
    this.signInStateStorage.set(STORAGE_KEY, JSON.stringify(signInState));
  }

  public get(): SignInState | null {
    const signInState = this.signInStateStorage.get(STORAGE_KEY, "");

    if (!signInState) {
      return null;
    }

    return JSON.parse(signInState);
  }

  public remove() {
    this.signInStateStorage.remove(STORAGE_KEY);
  }
}

/**
 * Browser session storage provider
 */
export class StorageSessionProvider implements SignInStateStorageProvider {
  set(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  get(key: string, defaultValue: string): string {
    const value = sessionStorage.getItem(key);

    if (!value) {
      return defaultValue;
    }

    return value;
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }
}
