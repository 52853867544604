export default class AbortablePromise<T> extends Promise<T> {
  private onAbort?: () => void;

  constructor(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void,
      onAbort: (abortHandler: () => void) => void
    ) => void
  ) {
    //temporary variable to get access to the abortCallback
    let onAbort = undefined;
    // call the promise constructor to get access to the abortCallback handler and assign it to the onAbort variable
    super((rs, rj) => executor(rs, rj, (abortCallback: () => void) => (onAbort = abortCallback)));
    this.onAbort = onAbort;
  }

  // call this method to invoke the onAbort function of the promise
  public abort() {
    if (this.onAbort) {
      this.onAbort();
    }
  }
}
