// src/Home.js
import { Card, Flex, HighlightMatch } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useTranslation } from 'react-i18next';
import { EncoderForm } from './components';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' width='100%'>
      <Card>
        <HighlightMatch query='Unified ID 2.0'>
          {t('description')}
        </HighlightMatch>
        <ol>
          <li>
            <HighlightMatch query='No Data Storage'>
              {t('noDataStorage')}
            </HighlightMatch>
          </li>
          <li>
            <HighlightMatch query='Anonymous File Names'>
              {t('anonymousFileNames')}
            </HighlightMatch>
          </li>
          <li>
            <HighlightMatch query='Shuffled Files'>
              {t('shuffledFiles')}
            </HighlightMatch>
          </li>
        </ol>
      </Card>

      <EncoderForm key={1} />
    </Flex>
  );
};

export default Home;
