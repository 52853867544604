// src/components/DownloadLinks.js
import { Flex, Link, Text } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { formatValue, randomSort } from '../utils';

const DownloadLinks = ({ files }) => {
  const { t } = useTranslation();

  return (
    <>
      {files.map((file, index) => (
        <Flex key={index} justifyContent='space-between'>
          <Link
            href={URL.createObjectURL(
              // eslint-disable-next-line
              new Blob([randomSort(file.content).join('\n')], {
                type: 'text/csv',
              })
            )}
            download={`${uuidv4()}.csv`}
            aria-label={t('encodedFile', { file: file.name })}
          >
            {t('encodedFile', { file: file.name })}
          </Link>
          <Text>{formatValue(file.content.length)} records.</Text>
        </Flex>
      ))}
    </>
  );
};

export default DownloadLinks;
