// src/components/FileTypeSelector.js
import {
  Flex,
  Radio,
  RadioGroupField,
  SelectField,
} from '@aws-amplify/ui-react';
import { getCountries } from 'libphonenumber-js';
import React from 'react';

const FileTypeSelector = ({
  fileType,
  onFileTypeChange,
  countryCode,
  onCountryCodeChange,
  tokens,
}) => {
  const countryOptions = getCountries().map((code) => (
    <option key={code} value={code}>
      {new Intl.DisplayNames(['en'], { type: 'region' }).of(code)}
    </option>
  ));

  return (
    <Flex direction='row' alignItems='center'>
      <RadioGroupField
        name='fileType'
        value={fileType}
        onChange={onFileTypeChange}
        marginTop={tokens.space.medium}
        direction='row'
      >
        <Radio value='email'>Email</Radio>
        <Radio value='phone'>Phone</Radio>
      </RadioGroupField>
      {fileType === 'phone' && (
        <SelectField
          name='countryCode'
          value={countryCode}
          onChange={onCountryCodeChange}
          marginTop={tokens.space.medium}
          size='small'
        >
          {countryOptions}
        </SelectField>
      )}
    </Flex>
  );
};

export default FileTypeSelector;
