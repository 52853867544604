const PATH_SEPARATOR = "/";

export function joinPaths(paths: string[]) {
  return paths
    .map((path) => {
      const sanitized = path.endsWith(PATH_SEPARATOR) ? path.substring(0, path.length - 1) : path;
      return sanitized.startsWith(PATH_SEPARATOR) ? sanitized.substring(1) : sanitized;
    })
    .join(PATH_SEPARATOR);
}
