export const injectStylesheetContent = (contents: string, styleTagId: string): void => {
  const style = document.createElement("style");
  style.innerHTML = contents;
  style.id = styleTagId;

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(style);
};

export const injectStylesheetLinkRef = (href: string): void => {
  const style = document.createElement("link");
  style.href = href;
  style.rel = "stylesheet";

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(style);
};

export const hasLinkRefContainingHrefText = (text: string): boolean => {
  const head = document.getElementsByTagName("head")[0];
  const linkElements = head.getElementsByTagName("link");

  for (let i = 0; i < linkElements.length; i++) {
    const link = linkElements[i];

    if (link.href.includes(text)) {
      return true;
    }
  }

  return false;
};

export const hasStyleTagWithId = (id: string): boolean => {
  const styleTagWithId = document.querySelector(`style[id=${id}]`);
  return styleTagWithId !== null;
};
