import {
  AuthenticationModeType,
  QuickAuthSignInOptions,
  OpenPassOptions,
  SignInButtonOptions,
  SignInButtonShape,
  SignInButtonShapeVariant,
  SignInButtonSize,
  SignInButtonText,
  SignInButtonTheme,
  VisibilityType,
} from "./types";
import { executeFunctionByName } from "./auth/utils/functions";
import OpenPassClient from "./auth/openPassClient";

export const onDomLoad = () => {
  if (window === undefined) {
    return;
  }
  wireUpQuickAuth();
  wireUpSignInButton();
};

const wireUpQuickAuth = () => {
  const quickTapId = "openpass-quick-auth";
  const container = document.getElementById(quickTapId);

  if (!container) return;
  const modeParam = container.getAttribute("data-openpass-quick-auth-mode");
  const clientIdParam = container.getAttribute("data-openpass-quick-auth-client-id");
  const show = container.getAttribute("data-openpass-quick-auth-show");
  const visibilityParam = container.getAttribute("data-openpass-quick-auth-visibility");
  const redirectUriParam = emptyStringAsUndefined(container.getAttribute("data-openpass-quick-auth-redirect-uri"));
  const popupSuccessCallbackParam = emptyStringAsUndefined(container.getAttribute("data-openpass-quick-auth-popup-success-callback"));
  const popupFailedCallbackParam = emptyStringAsUndefined(container.getAttribute("data-openpass-quick-auth-popup-failed-callback"));
  const ssoBaseUriParam = emptyStringAsUndefined(container.getAttribute("data-openpass-quick-auth-sso-base-uri"));
  const visibilityChangedCallbackParam = emptyStringAsUndefined(
    container.getAttribute("data-openpass-quick-auth-visibility-changed-callback")
  );

  // Ensure mode is valid if provided
  if (modeParam && modeParam !== "redirect" && modeParam !== "popup") {
    logErrorAndAddErrorAsDataAttribute(
      container,
      "Invalid mode provided for OpenPass quick-auth, please choose either 'redirect' or 'popup'"
    );
    return;
  }

  const mode: AuthenticationModeType = <AuthenticationModeType>modeParam ?? "redirect";

  // Ensure Client ID is provided
  if (!clientIdParam) {
    logErrorAndAddErrorAsDataAttribute(
      container,
      "No client id provided for OpenPass quick-auth, please add a 'data-client-id' attribute to the quick-auth container"
    );
    return;
  }

  // Show is a deprecated optional attribute, default to undefined if not provided
  if (show && !(show === "false" || show === "true")) {
    logErrorAndAddErrorAsDataAttribute(
      container,
      "Show parameter must be either 'true' or 'false' in the data-openpass-quick-auth-show attribute"
    );
    return;
  }
  // If show is undefined, then keep it undefined as the parameter is now deprecated
  const showValue = show === undefined ? undefined : show === "true";

  // Visibility is an optional attribute, default to "displayOnInit" if not provided
  if (
    visibilityParam &&
    !(visibilityParam === "displayOnInit" || visibilityParam === "displayOnInitIfSessionActive" || visibilityParam === "hideOnInit")
  ) {
    logErrorAndAddErrorAsDataAttribute(
      container,
      "Visibility parameter must be either 'displayOnInit', 'displayOnInitIfSessionActive', or 'hideOnInit' in the data-openpass-quick-auth-visibility attribute"
    );
    return;
  }
  const visibility: VisibilityType = <VisibilityType>visibilityParam ?? "displayOnInit";

  // Validate redirect required attributes
  if (mode === "redirect") {
    if (!redirectUriParam) {
      logErrorAndAddErrorAsDataAttribute(
        container,
        "No redirect URI provided for OpenPass quick-auth, please add a 'data-redirect-uri' attribute to the quick-auth container"
      );
      return;
    }
  }

  // Validate popup required attributes
  if (mode === "popup") {
    if (!popupSuccessCallbackParam) {
      logErrorAndAddErrorAsDataAttribute(
        container,
        "No popup success callback provided for OpenPass quick-auth, please add a 'data-popup-success-callback' attribute to the quick-auth container"
      );
      return;
    }
  }

  if (ssoBaseUriParam) {
    try {
      new URL(ssoBaseUriParam);
    } catch {
      logErrorAndAddErrorAsDataAttribute(
        container,
        "Invalid URI provided for 'data-sso-base-uri' attribute, please provide a valid OpenPass Auth API URI"
      );
      return;
    }
  }

  // add observer to check if the attribute data-show changes
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      // Double checking that we are only checking against the data-openpass-quick-auth-show attribute
      if (mutation.attributeName === "data-openpass-quick-auth-show") {
        if (!container) return; // the container could have been removed programmaticaly by the end user, in this case, abort.
        const showParameterValue = container.getAttribute("data-openpass-quick-auth-show");

        if (showParameterValue === "true") openPassClient.showQuickAuthSignIn();
        else if (showParameterValue === "false") openPassClient.hideQuickAuthSignIn();
        else {
          logErrorAndAddErrorAsDataAttribute(
            container,
            `Unexpected value ${showParameterValue} found in 'data-openpass-quick-auth-show' attribute, please report this to the OpenPass support. Expected value range: true, false.`
          );
        }
      }
    });
  });

  // Start observing the target node for the show attribute.
  observer.observe(container, { subtree: false, childList: false, attributeFilter: ["data-openpass-quick-auth-show"] });

  // Create OpenPass client
  const openPassOptions: OpenPassOptions = {
    clientId: clientIdParam,
    baseUrl: ssoBaseUriParam,
  };

  const openPassClient = new OpenPassClient(openPassOptions);

  // Render the button
  const options: QuickAuthSignInOptions = {
    parentContainerElementId: "openpass-quick-auth",
    authenticationMode: mode,
    redirectUrl: redirectUriParam,
    popupSuccessCallback: (signInResponse) => {
      if (popupSuccessCallbackParam) {
        executeFunctionByName(<string>popupSuccessCallbackParam, window, [signInResponse]);
      }
    },
    popupFailureCallback: (error) => {
      if (popupFailedCallbackParam) {
        executeFunctionByName(<string>popupFailedCallbackParam, window, [error]);
      }
    },
    show: showValue,
    visibility: visibility,
    visibilityChangedCallback: (data) => {
      if (visibilityChangedCallbackParam) {
        executeFunctionByName(<string>visibilityChangedCallbackParam, window, [data]);
      }
    },
  };

  try {
    openPassClient.renderQuickAuth(options);
  } catch (error) {
    const message = error instanceof Error ? error.message : "Error rendering quick-auth";
    logErrorAndAddErrorAsDataAttribute(container, message);
  }
};

const wireUpSignInButton = () => {
  const buttonContainer = document.getElementById("sign-in-with-openpass-button");

  if (!buttonContainer) {
    return;
  }

  const modeParam = buttonContainer.getAttribute("data-mode");
  const clientIdParam = buttonContainer.getAttribute("data-client-id");
  const redirectUriParam = emptyStringAsUndefined(buttonContainer.getAttribute("data-redirect-uri"));
  const popupSuccessCallbackParam = emptyStringAsUndefined(buttonContainer.getAttribute("data-popup-success-callback"));
  const popupFailedCallbackParam = emptyStringAsUndefined(buttonContainer.getAttribute("data-popup-failed-callback"));
  const shapeParam = buttonContainer.getAttribute("data-shape");
  const shapeVariantParam = buttonContainer.getAttribute("data-shape-variant");
  const sizeParam = buttonContainer.getAttribute("data-size");
  const textParam = buttonContainer.getAttribute("data-text");
  const themeParam = buttonContainer.getAttribute("data-theme");
  const additionalWidthParam = buttonContainer.getAttribute("data-additional-width");
  const ssoBaseUriParam = emptyStringAsUndefined(buttonContainer.getAttribute("data-sso-base-uri"));

  // Ensure mode is valid if provided
  if (modeParam && modeParam !== "redirect" && modeParam !== "popup") {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid mode provided for OpenPass sign-in button, please choose either 'redirect' or 'popup'"
    );
    return;
  }

  const mode: AuthenticationModeType = <AuthenticationModeType>modeParam ?? "redirect";

  // Ensure Client ID is provided
  if (!clientIdParam) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "No client id provided for OpenPass sign-in button, please add a 'data-client-id' attribute to the button container"
    );
    return;
  }

  // Ensure text type is valid if provided
  if (textParam && textParam !== "signin" && textParam !== "signin_with" && textParam !== "continue_with") {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid text type provided for OpenPass sign-in button, please choose either 'signin', 'signin_with' or 'continue_with'"
    );
    return;
  }

  // Validate redirect required attributes
  if (mode == "redirect") {
    if (!redirectUriParam) {
      logErrorAndAddErrorAsDataAttribute(
        buttonContainer,
        "No redirect URI provided for OpenPass sign-in button, please add a 'data-redirect-uri' attribute to the button container"
      );
      return;
    }
  }

  // Validate popup required attributes
  if (mode == "popup") {
    if (!popupSuccessCallbackParam) {
      logErrorAndAddErrorAsDataAttribute(
        buttonContainer,
        "No popup success callback provided for OpenPass sign-in button, please add a 'data-popup-success-callback' attribute to the button container"
      );
      return;
    }
  }

  if (ssoBaseUriParam) {
    try {
      new URL(ssoBaseUriParam);
    } catch {
      logErrorAndAddErrorAsDataAttribute(
        buttonContainer,
        "Invalid URI provided for 'data-sso-base-uri' attribute, please provide a valid OpenPass Auth API URI"
      );
      return;
    }
  }

  if (shapeParam && !(shapeParam === "standard" || shapeParam === "icon")) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid shape provided for OpenPass sign-in button, please choose either 'standard' or 'icon'"
    );
    return;
  }

  if (
    shapeVariantParam &&
    !(shapeVariantParam === "pill" || shapeVariantParam === "rectangle" || shapeVariantParam === "circle" || shapeVariantParam === "square")
  ) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid shape variant provided for OpenPass sign-in button, please choose either 'pill', 'rectangle', 'circle' or 'square'"
    );
    return;
  }

  if (shapeParam == "standard" && !(shapeVariantParam === undefined || shapeVariantParam === "pill" || shapeVariantParam === "rectangle")) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid shape variant provided for OpenPass sign-in button, please choose either 'pill' or 'rectangle'"
    );
    return;
  }

  if (shapeParam == "icon" && !(shapeVariantParam === undefined || shapeVariantParam === "circle" || shapeVariantParam === "square")) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid shape variant provided for OpenPass sign-in button, please choose either 'circle' or 'square'"
    );
    return;
  }

  if (additionalWidthParam) {
    const parsed = Number.parseInt(additionalWidthParam);

    if (Number.isNaN(parsed) || parsed < 0) {
      logErrorAndAddErrorAsDataAttribute(
        buttonContainer,
        "Invalid additional width provided for OpenPass sign-in button, please provide a positive number"
      );
      return;
    }
  }

  if (sizeParam && !(sizeParam === "x-large" || sizeParam === "large" || sizeParam === "medium" || sizeParam === "small")) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid size provided for OpenPass sign-in button, please choose either 'x-large', 'large', 'medium' or 'small'"
    );
    return;
  }

  if (themeParam && !(themeParam === "openpass" || themeParam === "light" || themeParam === "dark")) {
    logErrorAndAddErrorAsDataAttribute(
      buttonContainer,
      "Invalid theme provided for OpenPass sign-in button, please choose either 'openpass', 'light' or 'dark'"
    );
    return;
  }

  const shape = shapeParam ? <SignInButtonShape>shapeParam : undefined;
  const shapeVariant = shapeVariantParam ? <SignInButtonShapeVariant>shapeVariantParam : undefined;
  const theme = themeParam ? <SignInButtonTheme>themeParam : undefined;
  const size = sizeParam ? <SignInButtonSize>sizeParam : undefined;
  const text = textParam ? <SignInButtonText>textParam : undefined;
  const additionalWidth = additionalWidthParam ? Number.parseInt(additionalWidthParam) : undefined;

  // Create OpenPass client
  const openPassOptions: OpenPassOptions = {
    clientId: clientIdParam,
    baseUrl: ssoBaseUriParam,
  };
  const openPassClient = new OpenPassClient(openPassOptions);

  // Render the button
  const signInButtonOptions: SignInButtonOptions = {
    parentContainerElementId: "sign-in-with-openpass-button",
    authenticationMode: mode,
    redirectUrl: redirectUriParam,
    popupSuccessCallback: (signInResponse) => {
      executeFunctionByName(<string>popupSuccessCallbackParam, window, [signInResponse]);
    },
    popupFailedCallback: (error) => {
      if (popupFailedCallbackParam) {
        executeFunctionByName(<string>popupFailedCallbackParam, window, [error]);
      }
    },
    text: text,
    shape: shape,
    shapeVariant: shapeVariant,
    size: size,
    theme: theme,
    additionalWidth: additionalWidth,
  };

  try {
    openPassClient.renderSignInButton(signInButtonOptions);
  } catch (error) {
    const message = error instanceof Error ? error.message : "Error renering OpenPass sign-in button";
    logErrorAndAddErrorAsDataAttribute(buttonContainer, message);
  }
};

const emptyStringAsUndefined = (string: string | null): string | undefined => {
  return string === null || string === "" ? undefined : string;
};

const logErrorAndAddErrorAsDataAttribute = (container: HTMLElement, errorMessage: string) => {
  console.error(errorMessage);
  addErrorAsDataAttribute(container, errorMessage);
};

const addErrorAsDataAttribute = (container: HTMLElement, errorMessage: string) => {
  container.setAttribute("data-error", errorMessage);
  container.style.display = "none";
};
