import { createSignInButton } from "./embedded-assets/html";
import { createSignInButtonStylesheet } from "./embedded-assets/styles";
import { SdkError } from "./error/errors";
import { SignInButtonOptions } from "../types";
import { hasLinkRefContainingHrefText, hasStyleTagWithId, injectStylesheetContent, injectStylesheetLinkRef } from "./utils/stylesheets";
import { OpenPassApiClient } from "./api/openPassApiClient";
import PopupAuth from "./popup";
import RedirectAuth from "./redirect";

export const SIGN_IN_BUTTON_STYLESHEET_ID = "openpass-sign-in-button-stylesheet";

/**
 * Handles rendering and functionality of the automatically rendered sign in button.
 */
export default class SignInButton {
  private readonly redirectAuth: RedirectAuth;
  private readonly popupAuth: PopupAuth;
  private readonly apiClient: OpenPassApiClient;

  /**
   * Initializes a new instance of the SignInButton class.
   * @param redirectAuth - The redirect authentication object.
   * @param popupAuth - The popup authentication object.
   * @param apiClient - The OpenPass API Client client object.
   */
  constructor(redirectAuth: RedirectAuth, popupAuth: PopupAuth, apiClient: OpenPassApiClient) {
    this.popupAuth = popupAuth;
    this.redirectAuth = redirectAuth;
    this.apiClient = apiClient;
  }

  /**
   * Renders the sign in button.
   */
  public renderSignInButton(options: SignInButtonOptions): void {
    if (!options.parentContainerElementId) {
      throw new SdkError("No parent container element id provided for OpenPass sign-in button");
    }

    if (options.authenticationMode && !(options.authenticationMode === "redirect" || options.authenticationMode === "popup")) {
      throw new SdkError("Invalid authentication mode provided for OpenPass sign-in button, please choose either 'redirect' or 'popup'");
    }

    if (options.authenticationMode == "redirect" && !options.redirectUrl) {
      throw new SdkError("No redirect url provided for OpenPass sign-in button");
    }

    if (options.authenticationMode == "popup" && !options.popupSuccessCallback) {
      throw new SdkError("No popup success callback provided for OpenPass sign-in button");
    }

    if (options.shape && !(options.shape === "standard" || options.shape === "icon")) {
      throw new SdkError("Invalid shape provided for OpenPass sign-in button, please choose either 'standard' or 'icon'");
    }

    if (
      options.shapeVariant &&
      !(
        options.shapeVariant === "pill" ||
        options.shapeVariant === "rectangle" ||
        options.shapeVariant === "circle" ||
        options.shapeVariant === "square"
      )
    ) {
      throw new SdkError(
        "Invalid shape variant provided for OpenPass sign-in button, please choose either 'pill', 'rectangle', 'circle' or 'square'"
      );
    }

    if (
      options.shape == "standard" &&
      !(options.shapeVariant === undefined || options.shapeVariant === "pill" || options.shapeVariant === "rectangle")
    ) {
      throw new SdkError("Invalid shape variant provided for OpenPass sign-in button, please choose either 'pill' or 'rectangle'");
    }

    if (
      options.shape == "icon" &&
      !(options.shapeVariant === undefined || options.shapeVariant === "circle" || options.shapeVariant === "square")
    ) {
      throw new SdkError("Invalid shape variant provided for OpenPass sign-in button, please choose either 'circle' or 'square'");
    }

    if (options.additionalWidth && options.additionalWidth < 0) {
      throw new SdkError("Invalid width override provided for OpenPass sign-in button, please provide a positive number");
    }

    if (
      options.size &&
      !(options.size === "x-large" || options.size === "large" || options.size === "medium" || options.size === "small")
    ) {
      throw new SdkError("Invalid size provided for OpenPass sign-in button, please choose either 'x-large', 'large', 'medium' or 'small'");
    }

    if (options.theme && !(options.theme === "openpass" || options.theme === "light" || options.theme === "dark")) {
      throw new SdkError("Invalid theme provided for OpenPass sign-in button, please choose either 'openpass', 'light' or 'dark'");
    }

    if (options.text && !(options.text === "signin" || options.text === "signin_with" || options.text === "continue_with")) {
      throw new SdkError(
        "Invalid text type provided for OpenPass sign-in button, please choose either 'signin', 'signin_with' or 'continue_with'"
      );
    }

    const buttonContainer = document.getElementById(options.parentContainerElementId);

    if (!buttonContainer) {
      throw new SdkError(`No button container found with id '${options.parentContainerElementId}' for OpenPass sign-in button`);
    }

    // Inject stylesheet and fonts if not already present
    if (!hasStyleTagWithId(SIGN_IN_BUTTON_STYLESHEET_ID)) {
      injectStylesheetContent(createSignInButtonStylesheet(), SIGN_IN_BUTTON_STYLESHEET_ID);
    }

    if (!hasLinkRefContainingHrefText("Poppins")) {
      injectStylesheetLinkRef("https://fonts.googleapis.com/css?family=Poppins");
    }

    // Render Button
    const buttonElement = createSignInButton(options);

    // Attach click handler
    const signInButtonClickHandler = async (event: any) => {
      event.preventDefault();

      if (options.authenticationMode == "redirect") {
        await this.redirectAuth.signIn({
          redirectUrl: <string>options.redirectUrl,
          source: "SignInWithOpenPassButton",
          clientState: options.clientState,
          loginHint: options.loginHint,
        });
      } else if (options.authenticationMode == "popup") {
        try {
          const signInResponse = await this.popupAuth.signInWithPopup({
            redirectUrl: options.redirectUrl,
            source: "SignInWithOpenPassButton",
            clientState: options.clientState,
            loginHint: options.loginHint,
          });

          try {
            options.popupSuccessCallback?.(signInResponse);
          } catch (executeError) {
            console.error(`Error executing popup success callback, error: ${executeError}`);
          }
        } catch (error) {
          if (options.popupFailedCallback) {
            try {
              options.popupFailedCallback(error as SdkError);
            } catch (executeError) {
              console.error(`Error executing popup failed callback, error: ${executeError}`);
            }
          }
        }
      }
    };

    buttonElement.addEventListener("click", signInButtonClickHandler);
    buttonContainer.appendChild(buttonElement);

    // Send telemetry event
    // Do not await the result, this is a fire and forget operation
    this.apiClient.sendTelemetryEvent("SignInWithOpenPassButtonShown");
  }
}
