// src/OpenPassCallback.js
import { Card, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { OpenPassClient } from '@openpass/openpass-js-sdk';

const clientId = '6c9f3ac4ecd246e8b84674b42024ee0a';

const OpenPassCallback = () => {
  const [code, setCode] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get('code');
    const stateParam = urlParams.get('state');

    if (codeParam) setCode(codeParam);
    if (stateParam) setState(stateParam);

    const handleRedirect = async () => {
      const openPassClient = new OpenPassClient({
        clientId: clientId,
      });

      if (openPassClient.isAuthenticationRedirect()) {
        try {
          // Attempt to complete the sign-in process.
          const signInResponse =
            await openPassClient.handleAuthenticationRedirect();
          setEmail(signInResponse.idToken.email);
          console.log(signInResponse.idToken);
          console.log(signInResponse.clientState);
        } catch (e) {
          console.log(e);
          setError(e.message);
        }
      }
    };

    handleRedirect();
  }, []);

  return (
    <Flex direction='column' width='100%'>
      <Card>
        <Text>This is redirect URL.</Text>
        <Text>Code: {code}</Text>
        <Text>State: {state}</Text>
        <Text>Email: {email}</Text>
        <Text>Error: {error}</Text>
      </Card>
    </Flex>
  );
};

export default OpenPassCallback;
