// src/utils/formatValue.js

/**
 * Format the value to a human-readable format
 *
 * @param {number} value
 * @returns {string}
 */
const formatValue = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  } else {
    return value.toLocaleString();
  }
};

export default formatValue;
