import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import { shim } from "promise.prototype.finally";

import {
  RedirectSignInOptions,
  PopupSignInOptions,
  OpenPassOptions,
  OpenPassClientAuth,
  InlineSignInOptions,
  SignInButtonOptions,
} from "./types";
import { SdkError, AuthError } from "./auth/error/errors";
import { onDomLoad } from "./bootstrap";
import OpenPassClient from "./auth/openPassClient";

shim();

document.addEventListener("DOMContentLoaded", onDomLoad, { once: true });

export {
  OpenPassClientAuth,
  OpenPassClient,
  OpenPassOptions,
  RedirectSignInOptions,
  PopupSignInOptions,
  InlineSignInOptions,
  SignInButtonOptions,
  SdkError,
  AuthError,
};
