// src/components/Header.js
import {
  Flex,
  Heading,
  Image,
  Link,
  View,
  useTheme,
} from '@aws-amplify/ui-react';

const Header = ({ title }) => {
  const { tokens } = useTheme();

  return (
    <View padding={tokens.space.xs}>
      <Flex direction='row' alignItems='center'>
        <Link href='/'>
          <Image
            alt='PIER1 logo'
            src='/pier1-logo.png'
            height={tokens.space.medium}
          />
        </Link>
        <Heading level={6}>{title}</Heading>
      </Flex>
    </View>
  );
};

export default Header;
