// src/OpenPassPopup.js
import { Card, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { OpenPassClient } from '@openpass/openpass-js-sdk';

const clientId = '6c9f3ac4ecd246e8b84674b42024ee0a';
const redirectURI = 'https://dii-encoder.pier1.co.jp/op_callback';

const OpenPassPopup = () => {
  const [email, setEmail] = useState('');

  const openPassClient = new OpenPassClient({
    clientId: clientId,
  });

  useEffect(() => {
    openPassClient.renderSignInButton({
      parentContainerElementId: 'sign-in',
      authenticationMode: 'popup',
      redirectUrl: redirectURI,
      text: 'signin_with',
      popupSuccessCallback: (signInResponse) => {
        // access the user's email address from the response
        console.log(signInResponse.idToken.email);
        setEmail(signInResponse.idToken.email);

        // and then redirect the user back to the original content.
        window.location = signInResponse.originatingUri;
      },
      popupFailedCallback: (error) => {
        // log the error
        console.error(JSON.stringify(error));

        // Decide what to do if the sign-in fails (for example, the user closes the popup before authenticating):
        window.location = '/openpass-popup';
      },
    });
  }, []);

  return (
    <Flex direction='column' width='100%'>
      <Card id='sign-in' />
      <Card>
        <Text>Email: {email}</Text>
      </Card>
    </Flex>
  );
};

export default OpenPassPopup;
