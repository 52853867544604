// src/Inquiry.js
import {
  Button,
  Card,
  Flex,
  Heading,
  Input,
  Message,
  Text,
  TextAreaField,
} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { post } from 'aws-amplify/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import awsExports from './aws-exports';

// Configure Amplify
Amplify.configure(awsExports);

const Inquiry = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = { email, message };
    try {
      const response = await post({
        apiName: 'SendMail',
        path: '/send',
        options: {
          body: body,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      });

      const data = await response.response;
      if (data.statusCode === 200) {
        setEmail('');
        setMessage('');
        setError('');
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      setError(t('sendError'));
    }
  };

  return (
    <Flex direction='column' width='100%'>
      <Card>
        <Heading level={4}>{t('inquiryForm')}</Heading>
        <Text>{t('inquiryDescription')}</Text>
      </Card>

      <Card>
        <Text>Email:</Text>
        <Input
          type='email'
          name='email'
          value={email}
          required
          width={'50%'}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() =>
            setError(validator.isEmail(email) ? '' : t('invalidEmail'))
          }
        />
      </Card>

      <Card>
        <TextAreaField
          label={`${t('yourInquiryIs')}:`}
          name='message'
          value={message}
          required
          width={'100%'}
          rows={5}
          maxLength={1000}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Card>

      <Card>
        <Button
          variation='primary'
          isDisabled={email === '' || message === ''}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </Card>

      {error ? (
        <Card>
          <Message colorTheme='error'>{error}</Message>
        </Card>
      ) : null}
    </Flex>
  );
};

export default Inquiry;
