// src/components/Footer.js
import { Flex, Link, Text, useTheme, View } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { tokens } = useTheme();
  const { t } = useTranslation();

  return (
    <View padding={tokens.space.large}>
      <Flex justifyContent='center'>
        <Text>&copy; 2024 </Text><Link href='https://pier1.co.jp'>PIER1 Inc.</Link>
        <Link href='/privacy-policy'>{t('privacyPolicy')}</Link>
        <Link href='/terms-of-service'>{t('termsOfService')}</Link>
        <Link href='/inquiry'>{t('inquiryForm')}</Link>
      </Flex>
    </View>
  );
};

export default Footer;
