import { generateRandomString, hashSha256 } from "./crypto";
import { arrayBufferAsString } from "./buffer";
import { base64Encode } from "./base64";

export const generateCodeVerifier = (length = 43) => {
  const verifier = generateRandomString(length);
  return verifier;
};

export const generateCodeChallenge = async (verifier: string) => {
  const hash = await hashSha256(verifier);
  return base64Encode(arrayBufferAsString(hash));
};
