import { config, getOpenPassApiBaseUrl } from "../config";
import { PARAM_CODE_CHALLENGE_METHOD_VALUE, PARAM_CODE_RESPONSE_MODE_MESSAGE, POPUP_MESSAGE_SOURCE } from "./constants";
import { generateCodeChallenge, generateCodeVerifier } from "./utils/pkce";
import { generateStateValue } from "./utils/state";
import { buildAuthorizeUrl, matchesEventOrigin } from "./url";
import { OpenPassApiClient } from "./api/openPassApiClient";
import { isPostMessageSupported } from "./utils/browser";
import { isLargeBreakpoint, windowOpenPopupWithPercentage, windowOpenPopup } from "./utils/window";
import { ERROR_CODE_INVALID_AUTH_CODE } from "./error/codes";
import { AuthCancelledError, AuthError, SdkError } from "./error/errors";
import RedirectAuth from "./redirect";
import AbortablePromise from "./utils/abortablePromise";
import { OpenPassOptions, OpenPassTokens, SignInResponse } from "../types";
import { AuthCodeMessageResponse, AuthSession, InternalPopupSignInOptions } from "./internalTypes";

const POPUP_HEIGHT = 586;
const POPUP_WIDTH = 428;
const POPUP_NAME = "openpass:popup:login";

// Used to close the popup in the event the set authenticated method requires a remote API call to complete the authentication
// process, and that call suffers from increased latency.
const SET_AUTHENTICATED_TIMEOUT_IN_MS = 10000;

type AuthWindow = {
  window: Window;
  listener?: AbortablePromise<any>;
};

/**
 * Class which handles the Popup authorization flow.
 * This class redirects to the authorization server and waits for a response from the authorization server.
 * The response is sent via the window.postMessage API to support secure cross-origin communication.
 */
export default class PopupAuth {
  private readonly redirectApi: RedirectAuth;
  private readonly openPassClient: OpenPassApiClient;
  private readonly openPassOptions: OpenPassOptions;

  private popupWindow?: AuthWindow;

  constructor(OpenPassOptions: OpenPassOptions, redirectApi: RedirectAuth, openPassClient: OpenPassApiClient) {
    this.openPassOptions = OpenPassOptions;
    this.openPassClient = openPassClient;
    this.redirectApi = redirectApi;
  }

  refocusIfPopupExists(): boolean {
    if (!this.popupWindow || this.popupWindow.window.closed) {
      return false;
    }
    this.popupWindow.window.focus();
    return true;
  }

  async signInWithPopup(options: InternalPopupSignInOptions): Promise<SignInResponse> {
    // If an existing popup flow is in progress, close that and start a new one
    this.closePopupIfExists(this.popupWindow);

    let popup: Window | null;
    let popupErrorMessage: string | null = null;

    try {
      popup = this.openPopup();
    } catch (error) {
      popup = null;
      popupErrorMessage = (<Error>error)?.message;
    }

    if (!popup) {
      // If the popup was blocked, and a fallback redirectUrl was supplied, try to redirect instead
      if (options.redirectUrl) {
        this.redirectApi.signIn({
          ...options,
          redirectUrl: options.redirectUrl,
        });

        throw new SdkError("Using redirect instead of popup. This error should not be thrown because the redirect happens first.");
      }

      let exceptionMessage = "Popup window did not open correctly.";
      if (popupErrorMessage) {
        exceptionMessage += ` Error: ${popupErrorMessage}`;
      }

      throw new SdkError(exceptionMessage);
    }

    const authWindow: AuthWindow = { window: popup };

    this.popupWindow = authWindow;

    return this.doLogin(authWindow, options);
  }

  private async doLogin(authWindow: AuthWindow, options?: InternalPopupSignInOptions): Promise<SignInResponse> {
    const popupCloseHandler = () => {
      this.closePopupIfExists(authWindow);
    };

    window.addEventListener("beforeunload", popupCloseHandler);

    try {
      const verifier = generateCodeVerifier();

      const authSession: AuthSession = {
        clientState: options?.clientState,
        clientId: this.openPassOptions.clientId,
        redirectUrl: options?.redirectUrl,
        codeVerifier: verifier,
        codeChallenge: await generateCodeChallenge(verifier),
        codeChallengeMethod: PARAM_CODE_CHALLENGE_METHOD_VALUE,
        state: generateStateValue(),
        responseMode: PARAM_CODE_RESPONSE_MODE_MESSAGE,
        loginHint: options?.loginHint,
        consentJwt: options?.consentJwt,
        disableLoginHintEditing: options?.disableLoginHintEditing,
        originatingUri: window?.location?.href,
      };

      const source = options ? options.source : "Custom";

      const loginUri = buildAuthorizeUrl(
        getOpenPassApiBaseUrl(this.openPassOptions.baseUrl),
        config.SSO_AUTHORIZE_PATH,
        authSession,
        source
      );
      authWindow.window.location.replace(loginUri);

      return await this.waitForPopupResponse(authWindow, authSession);
    } catch (e) {
      if (!(e instanceof AuthCancelledError)) {
        this.closePopupIfExists(authWindow);
      }
      throw e;
    } finally {
      window.removeEventListener("beforeunload", popupCloseHandler);
    }
  }

  private async waitForPopupResponse(authWindow: AuthWindow, authSession: AuthSession): Promise<SignInResponse> {
    const authCodeResponse = await this.listenForPopupResponse(authWindow);

    if (!this.isAuthCodeValid(authCodeResponse, authSession) || !authCodeResponse.code) {
      throw new AuthError(
        authCodeResponse.error ? authCodeResponse.error : ERROR_CODE_INVALID_AUTH_CODE,
        authCodeResponse.errorDescription ? authCodeResponse.errorDescription : "Error, invalid authorization code response",
        authCodeResponse.errorUri ? authCodeResponse.errorUri : "",
        authSession.clientState
      );
    }

    const openPassTokens = await this.openPassClient.exchangeAuthCodeForTokens(authCodeResponse.code, authSession);

    return await this.completeAuthentication(authWindow, openPassTokens, authSession);
  }

  protected async completeAuthentication(
    authWindow: AuthWindow,
    tokens: OpenPassTokens,
    authSession: AuthSession
  ): Promise<SignInResponse> {
    const { idToken, rawIdToken, rawAccessToken, refreshToken, expiresIn, tokenType } = tokens;

    return new Promise<SignInResponse>((resolve, reject) => {
      //wait for identity to be set before closing the popup
      setTimeout(() => {
        reject(new SdkError("No Response received from popup"));
      }, SET_AUTHENTICATED_TIMEOUT_IN_MS);
      (async () => {
        try {
          this.closePopupIfExists(authWindow);

          resolve({
            clientState: authSession.clientState,
            originatingUri: authSession.originatingUri,
            idToken: idToken,
            rawIdToken: rawIdToken,
            accessToken: rawAccessToken,
            rawAccessToken: rawAccessToken,
            refreshToken: refreshToken,
            expiresIn: expiresIn,
            tokenType: tokenType,
          });
        } catch (e) {
          this.closePopupIfExists(authWindow);
          reject(e);
        }
      })();
    });
  }

  private async listenForPopupResponse(authWindow: AuthWindow) {
    let closeTimeout: number | undefined;
    let messageTimeout: number | undefined;
    let messageHandler: (event: MessageEvent) => void;

    const popupPromise = new AbortablePromise<AuthCodeMessageResponse>((resolve, reject, onAbort) => {
      closeTimeout = setInterval(() => {
        if (authWindow.window && authWindow.window.closed) {
          clearInterval(closeTimeout);
          window.removeEventListener("message", messageHandler);
          this.closePopupIfExists(authWindow);
          reject(new SdkError("Popup closed, authentication response not available"));
        }
      }, 100);

      messageHandler = (event: MessageEvent) => {
        //Check that the message is from the authorization server
        if (!matchesEventOrigin(event.origin, getOpenPassApiBaseUrl(this.openPassOptions.baseUrl)) || !event.data) {
          return;
        }

        const { data } = event;

        if (!data.source || data.source !== POPUP_MESSAGE_SOURCE) {
          return;
        }

        resolve(data as AuthCodeMessageResponse);
      };

      window.addEventListener("message", messageHandler, false);

      messageTimeout = setInterval(() => {
        clearInterval(messageTimeout);
        reject(new SdkError("No Response received from popup"));
      }, config.POPUP_RESPONSE_TIMEOUT_MS);

      onAbort(() => {
        clearInterval(closeTimeout);
        clearTimeout(messageTimeout);
        window.removeEventListener("message", messageHandler);
        reject(new AuthCancelledError("Popup window was closed"));
      });
    });

    authWindow.listener = popupPromise;

    return popupPromise.finally(() => {
      clearInterval(closeTimeout);
      clearTimeout(messageTimeout);
      window.removeEventListener("message", messageHandler);
    });
  }

  private openPopup(): Window | null {
    if (!isPostMessageSupported()) {
      return null;
    }

    if (isLargeBreakpoint()) {
      return windowOpenPopup("", POPUP_NAME, POPUP_WIDTH, POPUP_HEIGHT);
    } else {
      return windowOpenPopupWithPercentage("", POPUP_NAME, 100);
    }
  }

  private isAuthCodeValid(response: AuthCodeMessageResponse, authSession: AuthSession): boolean {
    const hasCodeAndStateParams = response.code && response.state;

    if (!hasCodeAndStateParams) {
      return false;
    }

    return authSession.state === response.state;
  }

  private closePopupIfExists(popupWindowToClose?: AuthWindow) {
    if (!popupWindowToClose) {
      return;
    }

    if (popupWindowToClose.window && !popupWindowToClose.window.closed) {
      try {
        popupWindowToClose.window.close();
      } catch (e) {
        console.warn("Error closing the openpass popup window", e);
      }
    }

    if (popupWindowToClose.listener) {
      try {
        popupWindowToClose.listener.abort();
        popupWindowToClose.listener = undefined;
      } catch (e) {
        console.warn("Error aborting the openpass popup listener", e);
      }
    }

    // Only set the popup to null if the closed window was the current popup
    if (popupWindowToClose == this.popupWindow) {
      this.popupWindow = undefined;
    }
  }
}
