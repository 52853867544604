import { getOpenPassApiBaseUrl } from "../config";
import { OpenPassApiClient } from "./api/openPassApiClient";
import { SdkError } from "./error/errors";
import {
  QuickAuthSignInOptions,
  OpenPassOptions,
  SignInOptionsBase,
  QuickAuthInitMessage,
  QuickAuthPopupSizeParams,
  QuickAuthMessageType,
} from "../types";
import { matchesEventOrigin } from "./url";
import PopupAuth from "./popup";
import RedirectAuth from "./redirect";

const DELAY_MS_DEFAULT = 1000;

/**
 * Handles rendering and functionality of quick-auth dialog.
 */
export default class QuickAuth {
  private readonly redirectAuth: RedirectAuth;
  private readonly popupAuth: PopupAuth;
  private readonly openPassOptions: OpenPassOptions;
  private readonly apiClient: OpenPassApiClient;

  private quickAuthSignInOptions: QuickAuthSignInOptions | null = null;
  private isInitialized = false;
  private isVisible?: boolean;
  private parentContainer: HTMLElement | undefined;
  private quickAuthDialogIFrame?: HTMLIFrameElement;

  /**
   * Instantiates a new instance of the QuickAuth class.
   * @param openPassOptions - The OpenPass options.
   * @param redirectAuth - The redirect authentication object.
   * @param popupAuth - The popup authentication object.
   * @param apiClient - Holds all methods that call the OpenPass API.
   */
  constructor(openPassOptions: OpenPassOptions, redirectAuth: RedirectAuth, popupAuth: PopupAuth, apiClient: OpenPassApiClient) {
    this.openPassOptions = openPassOptions;
    this.popupAuth = popupAuth;
    this.redirectAuth = redirectAuth;
    this.apiClient = apiClient;
  }

  /**
   * Renders the quick-auth dialog with the specified options.
   * @param options - The quick-auth dialog options.
   * @throws {SdkError} If the required options are missing.
   */
  public render(options: QuickAuthSignInOptions): void {
    this.quickAuthSignInOptions = options;

    const { redirectUrl, show, visibility, parentContainerElementId, authenticationMode, popupSuccessCallback } = options;

    if (visibility === undefined) {
      // default visibility to "displayOnInit" unless `show` has been explicitly set to false.
      this.quickAuthSignInOptions.visibility = show === undefined || show === true ? "displayOnInit" : "hideOnInit";
    }

    if (!parentContainerElementId) {
      throw new SdkError("parentContainerElementId is required for quick-auth sign-in method.");
    }

    const temporaryParentContainer = document.getElementById(parentContainerElementId);

    if (!temporaryParentContainer)
      throw new SdkError(`Cannot locate parent container element "${parentContainerElementId}" for quick-auth`);

    this.parentContainer = temporaryParentContainer;

    if (authenticationMode !== "popup" && authenticationMode !== "redirect") {
      throw new SdkError(`Invalid authentication mode: ${authenticationMode}.`);
    }

    if (authenticationMode === "popup" && !popupSuccessCallback) {
      throw new SdkError("Must provide popupSuccessCallback for quick-auth when authentication mode is popup.");
    }

    if (authenticationMode === "redirect" && !redirectUrl) {
      throw new SdkError("Must provide redirectUrl for quick-auth when authentication mode is redirect.");
    }

    this.quickAuthDialogIFrame = this.createHiddenQuickAuthIframe(this.openPassOptions.clientId, this.parentContainer);
    window.addEventListener("message", (event: MessageEvent) => this.messageHandler(event, options, this.quickAuthDialogIFrame!));

    this.parentContainer.appendChild(this.quickAuthDialogIFrame);
  }

  private showWithDelay() {
    if (!this.isInitialized) throw new SdkError("Quick Auth is not initialized. Call `render` first.");
    if (this.isVisible === true) return;

    // Set this now, so that this method can't be executed again before the timeout.
    this.isVisible = true;

    const timeout = setTimeout(() => {
      this.showInstantly(true);
      clearTimeout(timeout);
    }, this.quickAuthSignInOptions?.delayMs ?? DELAY_MS_DEFAULT);
  }

  public showInstantly = (ignoreVisibilityCheck: boolean = false) => {
    if (!this.isInitialized) throw new SdkError("Quick Auth is not initialized. Call `render` first.");
    if (!ignoreVisibilityCheck && this.isVisible === true) return;

    this.isVisible = true;
    this.quickAuthDialogIFrame!.style.display = "block";

    if (this.quickAuthSignInOptions?.visibilityChangedCallback) {
      this.quickAuthSignInOptions.visibilityChangedCallback({ visibility: "visible" });
    }

    this.apiClient.sendTelemetryEvent("SignInWithOpenPassQuickSignShown");
  };

  public hideInstantly = () => {
    if (!this.isInitialized) throw new SdkError("Quick Auth is not initialized. Call `render` first.");
    if (this.isVisible === false) return;

    this.isVisible = false;
    this.quickAuthDialogIFrame!.style.display = "none";

    if (this.quickAuthSignInOptions?.visibilityChangedCallback) {
      this.quickAuthSignInOptions.visibilityChangedCallback({ visibility: "hidden" });
    }
  };

  private messageHandler(event: MessageEvent, options: QuickAuthSignInOptions, quickAuthDialogIFrame: HTMLIFrameElement) {
    if (!matchesEventOrigin(event.origin, getOpenPassApiBaseUrl(this.openPassOptions.baseUrl))) return;

    if (event.data && event.data.type) {
      switch (event.data.type) {
        case QuickAuthMessageType.INIT:
          this.handleInitializedMessage(event, options);
          break;
        case QuickAuthMessageType.CONTINUE:
          this.handleContinueButtonMessage(event, options);
          break;
        case QuickAuthMessageType.CLOSE:
          this.handleCloseButtonMessage(quickAuthDialogIFrame);
          break;
      }
    }
  }

  private handleInitializedMessage(event: MessageEvent<QuickAuthInitMessage>, options: QuickAuthSignInOptions) {
    const {
      data: { hasSession, popupWidth, popupHeight },
    } = event;

    this.setupQuickAuthIframeHeight({ width: popupWidth, height: popupHeight });

    this.isInitialized = true;

    switch (options.visibility) {
      case "displayOnInit":
        this.showWithDelay();
        break;

      case "displayOnInitIfSessionActive":
        if (hasSession) {
          this.showWithDelay();
        } else {
          this.hideInstantly();
        }
        break;

      case "hideOnInit":
        this.hideInstantly();
        break;
    }
  }

  private async handleContinueButtonMessage(event: MessageEvent, options: QuickAuthSignInOptions) {
    const { data } = event;

    const popupSuccessCallback = options.popupSuccessCallback;
    const popupFailureCallback = options.popupFailureCallback;
    const authenticationMode = options.authenticationMode;

    const signInOptionsBase: SignInOptionsBase = {
      clientState: options.clientState,
      disableLoginHintEditing: false,
      loginHint: data.loginHint,
      consentJwt: data.consentJwt,
    };

    switch (authenticationMode) {
      case "popup":
        try {
          // refocus popup if already open
          if (this.popupAuth.refocusIfPopupExists()) {
            break;
          }

          // otherwise start a new flow
          const popupResult = await this.popupAuth.signInWithPopup({
            ...signInOptionsBase,
            redirectUrl: options.redirectUrl,
            source: "SignInWithOpenPassQuickAuth",
          });

          if (popupSuccessCallback) {
            this.hideInstantly();
            popupSuccessCallback(popupResult);
          }
        } catch (error) {
          if (error instanceof SdkError) {
            if (popupFailureCallback) {
              popupFailureCallback(error);
            }
          } else {
            console.error(error);
          }
        }
        break;

      case "redirect":
        this.redirectAuth.signIn({
          ...signInOptionsBase,
          redirectUrl: options.redirectUrl!,
          source: "SignInWithOpenPassQuickAuth",
        });
        break;

      default:
        console.error("Invalid authentication mode: " + authenticationMode);
        break;
    }
  }

  private handleCloseButtonMessage(iFrame: HTMLIFrameElement) {
    this.hideInstantly();
    this.apiClient.sendTelemetryEvent("SignInWithOpenPassQuickSignDismissed");
  }

  private createHiddenQuickAuthIframe(clientId: string, parentContainer: HTMLElement): HTMLIFrameElement {
    const quickAuthIFrame = document.createElement("iframe") as HTMLIFrameElement;

    const openpassBaseUrl = getOpenPassApiBaseUrl(this.openPassOptions.baseUrl);
    const quickAuthFrameUrl = new URL(`/quick-auth`, openpassBaseUrl);
    quickAuthFrameUrl.searchParams.append("client_id", clientId);

    parentContainer.style.zIndex = "9999";

    quickAuthIFrame.src = quickAuthFrameUrl.toString();
    quickAuthIFrame.width = "100%";

    quickAuthIFrame.style.display = "none";
    quickAuthIFrame.style.border = "none";
    quickAuthIFrame.style.overflow = "hidden";

    const tabletWidth = 640;
    const browserWidth = window.innerWidth;

    if (browserWidth > tabletWidth) {
      parentContainer.style.position = "fixed";
      parentContainer.style.top = "120px";
      parentContainer.style.right = "20px";
    } else {
      parentContainer.style.width = "100%";
      parentContainer.style.position = "fixed";
      parentContainer.style.bottom = "0px";
      parentContainer.style.left = "0px";
    }

    return quickAuthIFrame;
  }

  private setupQuickAuthIframeHeight({ width, height }: QuickAuthPopupSizeParams) {
    if (!this.quickAuthDialogIFrame || !this.parentContainer) {
      throw new SdkError("Quick Auth is not rendered. Call `render` first.");
    }

    this.quickAuthDialogIFrame.height = `${height}px`;
    this.parentContainer.style.height = `${height}px`;
    this.quickAuthDialogIFrame.style.minWidth = `${width}px`;
  }
}
