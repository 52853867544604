import { config, getOpenPassApiBaseUrl } from "../config";
import { PARAM_CODE_CHALLENGE_METHOD_VALUE } from "./constants";
import { generateCodeChallenge, generateCodeVerifier } from "./utils/pkce";
import { generateStateValue } from "./utils/state";
import { buildAuthorizeUrl, isAuthRedirectUrl, parseAuthRedirectUrlParams } from "./url";
import { OpenPassApiClient } from "./api/openPassApiClient";
import { ERROR_CODE_INVALID_AUTH_SESSION, ERROR_CODE_INVALID_REDIRECT } from "./error/codes";
import { AuthError, SdkError } from "./error/errors";
import { SignInStateRepository } from "./signInState";
import { OpenPassOptions, SignInResponse } from "../types";
import { AuthRedirectUrlParams, AuthSession, InternalRedirectSignInOptions } from "./internalTypes";

/**
 * Class which handles the redirect authorization flow.
 * To realize this flow this class implements the Authorization Code Flow with Proof Key for Code Exchange (PKCE).
 * The redirect flow involves redirecting to an authorization service, and then handle the login response send via the redirect.
 */
export default class RedirectAuth {
  private readonly signInStateRepository: SignInStateRepository;
  private readonly openPassApiClient: OpenPassApiClient;
  private readonly openPassOptions: OpenPassOptions;

  constructor(OpenPassOptions: OpenPassOptions, signInStateRepository: SignInStateRepository, openPassAuthClient: OpenPassApiClient) {
    this.openPassOptions = OpenPassOptions;
    this.openPassApiClient = openPassAuthClient;
    this.signInStateRepository = signInStateRepository;
  }

  /**
   * Initiate the login flow by redirecting to the authorization server
   * @param options   the login options
   */
  public async signIn(options: InternalRedirectSignInOptions) {
    if (!options.redirectUrl) {
      throw new SdkError("Error redirectUrl is invalid. Please use a valid redirectUrl");
    }

    const verifier = generateCodeVerifier();

    const authSession: AuthSession = {
      clientState: options.clientState,
      clientId: this.openPassOptions.clientId,
      redirectUrl: options.redirectUrl,
      codeVerifier: verifier,
      codeChallenge: await generateCodeChallenge(verifier),
      codeChallengeMethod: PARAM_CODE_CHALLENGE_METHOD_VALUE,
      state: generateStateValue(),
      loginHint: options.loginHint,
      consentJwt: options.consentJwt,
      disableLoginHintEditing: options.disableLoginHintEditing,
      originatingUri: window?.location?.href,
    };
    this.signInStateRepository.add(authSession);

    const loginUri = buildAuthorizeUrl(
      getOpenPassApiBaseUrl(this.openPassOptions.baseUrl),
      config.SSO_AUTHORIZE_PATH,
      authSession,
      options.source
    );

    window.location.href = loginUri;
  }

  /**
   * Determines if a redirect authorization session is in progress, and the current browser url meets the conditions to consider it as a valid redirection from the
   * authorization server for the current login session.
   */
  public isAuthenticationRedirect(): boolean {
    const authSession = this.signInStateRepository.get();

    if (!authSession) {
      console.warn("Unable to authenticate, a login session may not have being started. Possibly a call to login is required");
      return false;
    }

    return isAuthRedirectUrl(window.location.href, parseAuthRedirectUrlParams(window.location.search), authSession.redirectUrl);
  }

  /**
   * Handles a login redirect and attempts to complete the authorization flow.
   */
  public async handleAuthenticationRedirect(): Promise<SignInResponse> {
    const authSession = this.signInStateRepository.get();

    if (!authSession) {
      throw new AuthError(
        ERROR_CODE_INVALID_AUTH_SESSION,
        "Unable to authenticate, a login session may not have being started. Possibly a call to login is required",
        ""
      );
    }

    try {
      if (!this.isAuthenticationRedirect()) {
        throw new AuthError(ERROR_CODE_INVALID_REDIRECT, "Unable to validate the redirect response", "", authSession.clientState);
      }

      const redirectParams = parseAuthRedirectUrlParams(window.location.search);

      if (!this.isRedirectUrlValid(authSession, redirectParams) || !redirectParams.code) {
        throw new AuthError(
          redirectParams.error ? redirectParams.error : ERROR_CODE_INVALID_REDIRECT,
          redirectParams.errorDescription ? redirectParams.errorDescription : "Unable to validate the redirect response",
          redirectParams.errorUri ? redirectParams.errorUri : "",
          authSession.clientState
        );
      }

      const tokens = await this.openPassApiClient.exchangeAuthCodeForTokens(redirectParams.code, authSession);
      const { idToken, rawIdToken, accessToken, refreshToken, tokenType, expiresIn } = tokens;

      return {
        clientState: authSession.clientState,
        originatingUri: authSession.originatingUri,
        idToken: idToken,
        rawIdToken: rawIdToken,
        accessToken: accessToken,
        rawAccessToken: accessToken,
        refreshToken: refreshToken,
        tokenType: tokenType,
        expiresIn: expiresIn,
      };
    } finally {
      this.signInStateRepository.remove();
    }
  }

  private isRedirectUrlValid(authSession: AuthSession, params: AuthRedirectUrlParams): boolean {
    if (!authSession) {
      return false;
    }

    const hasCodeAndStateParams = params.code && params.state;

    if (!hasCodeAndStateParams) {
      return false;
    }

    return authSession.state === params.state;
  }
}
