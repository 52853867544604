import { OpenPassApiClient } from "./api/openPassApiClient";
import { AuthorizeDeviceData, AuthorizeDeviceOptions, DeviceTokenWithStatus, IdToken, OpenPassOptions } from "../types";
import { decodeIdTokenJwt } from "./utils/idTokenJwtDecode";

export default class DeviceAuthGrant {
  private readonly openPassApiClient: OpenPassApiClient;
  private readonly openPassOptions: OpenPassOptions;

  /**
   * Constructs a new instance of the DeviceAuthGrant class.
   * @param OpenPassOptions - The options for OpenPass.
   * @param openPassAuthClient - The client for OpenPass API.
   */
  constructor(openPassOptions: OpenPassOptions, openPassAuthClient: OpenPassApiClient) {
    this.openPassOptions = openPassOptions;
    this.openPassApiClient = openPassAuthClient;
  }

  /**
   * Authorizes a device via the device authorization grant flow.
   * @param options - The options for device authorization.
   * @returns A promise that resolves to the data of the authorized device.
   */
  public async authorizeDevice(options: AuthorizeDeviceOptions): Promise<AuthorizeDeviceData> {
    const authorizeDeviceResponse = await this.openPassApiClient.authorizeDevice(
      this.openPassOptions.clientId,
      options.loginHint,
      options.disableLoginHintEditing
    );

    return {
      deviceCode: authorizeDeviceResponse.device_code,
      userCode: authorizeDeviceResponse.user_code,
      verificationUri: authorizeDeviceResponse.verification_uri,
      verificationUriComplete: authorizeDeviceResponse.verification_uri_complete,
      expiresIn: authorizeDeviceResponse.expires_in,
      interval: authorizeDeviceResponse.interval,
    };
  }

  /**
   * Retrieves the device token for the provided device code, or a status if the authentication is still pending.
   * @param deviceCode - The code of the device (which is returned by the authorizeDevice method)
   * @returns A promise that resolves to the device token with its status.
   */
  public async deviceToken(deviceCode: string): Promise<DeviceTokenWithStatus> {
    const deviceTokenResponse = await this.openPassApiClient.deviceToken(this.openPassOptions.clientId, deviceCode);

    var deviceTokenWithStatus: DeviceTokenWithStatus = {
      status: deviceTokenResponse.status,
    };

    if (deviceTokenResponse.tokensResponse) {
      const idToken = decodeIdTokenJwt(deviceTokenResponse.tokensResponse.id_token);

      deviceTokenWithStatus.tokens = {
        idToken: idToken,
        rawIdToken: deviceTokenResponse.tokensResponse.id_token,
        accessToken: deviceTokenResponse.tokensResponse.access_token,
        rawAccessToken: deviceTokenResponse.tokensResponse.access_token,
        refreshToken: deviceTokenResponse.tokensResponse.refresh_token,
        tokenType: deviceTokenResponse.tokensResponse.token_type,
        expiresIn: deviceTokenResponse.tokensResponse.expires_in,
      };
    }

    return deviceTokenWithStatus;
  }
}
