// A mapping of what sizes to use based on the button shape, and the text used in the button

export const logoSizes = {
  xLarge: 22,
  large: 18,
  medium: 14,
  small: 10,
};

export const createSignInButtonStylesheet = (): string => {
  return `
    .op-js-sdk-siwopb__root {
      border: none;
      cursor: pointer;
      display: flex;
      font-family: Poppins, sans-serif;
      text-align: center;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
    }

    .op-js-sdk-siwopb__theme-openpass {
      background-color: #012359;
      color: #FFFFFF;
    }

    .op-js-sdk-siwopb__theme-light {
      background-color: #FFFFFF;
      color: #3C4043;

      border-style: solid;
      border-color: #DADCE0;
      border-width: 1px;
    }

    .op-js-sdk-siwopb__theme-dark {
      background-color: #202124;
      color: #FFFFFF;
    }

    .op-js-sdk-siwopb__size-shape-standard-x-large {
      font-weight: 400;
      font-size: 16px;
      
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 16px;
      padding-right: 16px;

      height: 48px;
      min-height: 48px;
      max-height: 48px;
    }

    .op-js-sdk-siwopb__size-shape-standard-pill-x-large {
      border-radius: 100px;
    }

    .op-js-sdk-siwopb__size-shape-standard-rectangle-x-large {
      border-radius: 4px;
    }
    
    .op-js-sdk-siwopb__size-shape-standard-large {
      font-weight: 400;
      font-size: 14px;
      
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 12px;
      padding-right: 12px;

      height: 40px;
      min-height: 40px;
      max-height: 40px;
    }

    .op-js-sdk-siwopb__size-shape-standard-pill-large {
      border-radius: 20px;
    }

    .op-js-sdk-siwopb__size-shape-standard-rectangle-large {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-standard-medium {
      font-weight: 400;
      font-size: 12px;

      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 12px;
      padding-right: 12px;
      
      height: 32px;
      min-height: 32px;
      max-height: 32px;
    }

    .op-js-sdk-siwopb__size-shape-standard-pill-medium {
      border-radius: 16px;
    }

    .op-js-sdk-siwopb__size-shape-standard-rectangle-medium {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-standard-small {
      font-weight: 300;
      font-size: 11px;

      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 8px;
      padding-right: 8px;
      
      height: 20px;
      min-height: 20px;
      max-height: 20px;
    }

    .op-js-sdk-siwopb__size-shape-standard-pill-small {
      border-radius: 10px;
    }

    .op-js-sdk-siwopb__size-shape-standard-rectangle-small {
      border-radius: 4px;
    }


    .op-js-sdk-siwopb__size-shape-icon-x-large {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 12px;
      padding-right: 12px;

      height: 48px;
      min-height: 48px;
      max-height: 48px;
    }

    .op-js-sdk-siwopb__size-shape-icon-square-x-large {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-icon-circle-x-large {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-icon-large {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 10px;
      padding-right: 10px;

      height: 40px;
      min-height: 40px;
      max-height: 40px;
    }

    .op-js-sdk-siwopb__size-shape-icon-square-large {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-icon-circle-large {
      border-radius: 20px;
    }

    .op-js-sdk-siwopb__size-shape-icon-medium {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 8px;
      padding-right: 8px;

      height: 32px;
      min-height: 32px;
      max-height: 32px;
    }

    .op-js-sdk-siwopb__size-shape-icon-square-medium {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-icon-circle-medium {
      border-radius: 16px;
    }

    .op-js-sdk-siwopb__size-shape-icon-small {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 4px;
      padding-right: 4px;
      
      height: 20px;
      min-height: 20px;
      max-height: 20px;
    }

    .op-js-sdk-siwopb__size-shape-icon-square-small {
      border-radius: 4px;
    }

    .op-js-sdk-siwopb__size-shape-icon-circle-small {
      border-radius: 10px;
    }

    .op-js-sdk-siwopb__logo-x-large {
      height: ${logoSizes.xLarge}px;
      width: ${logoSizes.xLarge}px;
    }

    .op-js-sdk-siwopb__logo-large {
      height: ${logoSizes.large}px;
      width: ${logoSizes.large}px;
    }

    .op-js-sdk-siwopb__logo-medium {
      height: ${logoSizes.medium}px;
      width: ${logoSizes.medium}px;
    }

    .op-js-sdk-siwopb__logo-small {
      height: ${logoSizes.small}px;
      width: ${logoSizes.small}px;
    }

    .op-js-sdk-siwopb__logo-shape-standard-x-large {
      margin-right: 10px;
    }

    .op-js-sdk-siwopb__logo-shape-standard-large {
      margin-right: 10px;
    }

    .op-js-sdk-siwopb__logo-shape-standard-medium {
      margin-right: 10px;
    }

    .op-js-sdk-siwopb__logo-shape-standard-small {
      margin-right: 10px;
    }

    .op-js-sdk-siwopb__logo-shape-icon-x-large {
      margin-left: auto;
      margin-right: auto;
      vertical-align: middle;
    }

    .op-js-sdk-siwopb__logo-shape-icon-large {
      margin-left: auto;
      margin-right: auto;
      vertical-align: middle;
    }

    .op-js-sdk-siwopb__logo-shape-icon-medium {
      margin-left: auto;
      margin-right: auto;
      vertical-align: middle;
    }

    .op-js-sdk-siwopb__logo-shape-icon-small {
      margin-left: auto;
      margin-right: auto;
      vertical-align: middle;
    }
  `;
};
