// src/components/EncoderForm.js
import {
  Button,
  Card,
  Heading,
  Link,
  Loader,
  Message,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { DownloadLinks, FileDropZone, FileTypeSelector } from '../components';
import { encodeFile } from '../utils';

const EncoderForm = () => {
  const { tokens } = useTheme();
  const { t } = useTranslation();

  const [fileType, setFileType] = useState('email');
  const [countryCode, setCountryCode] = useState('JP');
  const [files, setFiles] = React.useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleDropComplete = async ({ acceptedFiles, rejectedFiles }) => {
    setIsLoading(true);
    setSuccess(false);
    setError('');

    try {
      // Ensure all files are accepted
      const encodedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const encodedFile = await encodeFile(file, fileType, countryCode);
          return { ...file, ...encodedFile };
        })
      );

      // Check if any files are empty
      encodedFiles.forEach((file) => {
        if (file.content.length === 0) {
          setSuccess(false);
          setError(t('noData', { file: file.name }));
        } else {
          setSuccess(true);
          setError('');
        }
      });

      setFiles((prevFiles) => [...prevFiles, ...encodedFiles]);
    } catch (error) {
      console.error('Error encoding files:', error);
    }

    setIsLoading(false);
  };

  return (
    <View width='100%'>
      <Card margin={tokens.space.small}>
        <Heading level={6}>{t('readTermsOfService')}</Heading>
        <Card margin={0}>
          <Link
            href='/terms-of-service'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('termsOfService')}
            <FaExternalLinkAlt />
          </Link>
        </Card>
      </Card>

      <Card margin={tokens.space.small}>
        <Heading level={6}>{t('fileType')}</Heading>
        <FileTypeSelector
          fileType={fileType}
          onFileTypeChange={handleFileTypeChange}
          countryCode={countryCode}
          onCountryCodeChange={handleCountryCodeChange}
          tokens={tokens}
        />
      </Card>

      <Card margin={tokens.space.small}>
        <Heading level={6}>{t('uploadFile')}</Heading>
        <FileDropZone onDrop={handleDropComplete} files={files} />
        <Button
          size='small'
          onClick={() => {
            setFiles([]);
            setSuccess(false);
            setError('');
          }}
        >
          {t('clear')}
        </Button>
      </Card>

      <Card margin={tokens.space.small}>
        <Heading level={6}>{t('downloadLinks')}</Heading>
        <DownloadLinks files={files} />
      </Card>

      {isLoading ? (
        <Card margin={tokens.space.small}>
          <Loader variation='linear' />
        </Card>
      ) : null}

      {success ? (
        <Card margin={tokens.space.small}>
          <Message colorTheme='success'>{t('encodeSuccess')}</Message>
        </Card>
      ) : null}

      {error ? (
        <Card margin={tokens.space.small}>
          <Message colorTheme='error'>{error}</Message>
        </Card>
      ) : null}
    </View>
  );
};

export default EncoderForm;
