import { AuthRedirectUrlParams, AuthSession, SignInSource } from "./internalTypes";
import {
  PARAM_CLIENT_ID,
  PARAM_CODE,
  PARAM_CODE_CHALLENGE,
  PARAM_CODE_CHALLENGE_METHOD,
  PARAM_CODE_CONSENT_TOKEN_JWT,
  PARAM_CODE_DISABLE_LOGIN_HINT_EDITING,
  PARAM_CODE_LOGIN_HINT,
  PARAM_CODE_RESPONSE_MODE,
  PARAM_ERROR,
  PARAM_ERROR_DESCRIPTION,
  PARAM_ERROR_URI,
  PARAM_REDIRECT_URI,
  PARAM_RESPONSE_TYPE,
  PARAM_RESPONSE_TYPE_VALUE,
  PARAM_SCOPE,
  PARAM_SCOPE_VALUE,
  PARAM_SDK_NAME,
  PARAM_SDK_VERSION,
  PARAM_STATE,
  PARAM_SIGN_IN_SOURCE,
  SDK_NAME,
} from "./constants";
import { joinPaths } from "./utils/path";
import { version as sdkVersion } from "../../package.json";

export const parseAuthRedirectUrlParams = (queryString: string): AuthRedirectUrlParams => {
  const params = new URLSearchParams(queryString);
  return {
    code: getParamValue(PARAM_CODE, params),
    state: getParamValue(PARAM_STATE, params),
    error: getParamValue(PARAM_ERROR, params),
    errorDescription: getParamValue(PARAM_ERROR_DESCRIPTION, params),
    errorUri: getParamValue(PARAM_ERROR_URI, params),
  };
};

export const isAuthRedirectUrl = (url: string, urlParams: AuthRedirectUrlParams, redirectUrl?: string): boolean => {
  if (!url || !redirectUrl) {
    return false;
  }

  const urlContainsRedirectUrl = url.toLowerCase().indexOf(redirectUrl.toLowerCase()) != -1;
  const hasStateAndCodeParams = urlParams.state !== null && urlParams.code !== null;
  const hasErrorParam = urlParams.error !== null || urlParams.errorDescription !== null;
  const hasRedirectParams = hasStateAndCodeParams || hasErrorParam;

  return urlContainsRedirectUrl && hasRedirectParams;
};

export const buildAuthorizeUrl = (baseUrl: string, authorizeEndpoint: string, session: AuthSession, source: SignInSource) => {
  const params = new URLSearchParams();

  params.set(PARAM_RESPONSE_TYPE, PARAM_RESPONSE_TYPE_VALUE);
  params.set(PARAM_CLIENT_ID, session.clientId);
  params.set(PARAM_SCOPE, PARAM_SCOPE_VALUE);
  params.set(PARAM_STATE, session.state);
  params.set(PARAM_SDK_NAME, SDK_NAME);
  params.set(PARAM_SDK_VERSION, sdkVersion);
  params.set(PARAM_SIGN_IN_SOURCE, source);

  if (session.redirectUrl) {
    params.set(PARAM_REDIRECT_URI, session.redirectUrl);
  }

  if (session.codeChallengeMethod) {
    params.set(PARAM_CODE_CHALLENGE_METHOD, session.codeChallengeMethod);
  }
  if (session.codeChallenge) {
    params.set(PARAM_CODE_CHALLENGE, session.codeChallenge);
  }
  if (session.responseMode) {
    params.set(PARAM_CODE_RESPONSE_MODE, session.responseMode);
  }
  if (session.loginHint) {
    params.set(PARAM_CODE_LOGIN_HINT, session.loginHint);
  }
  if (session.consentJwt) {
    params.set(PARAM_CODE_CONSENT_TOKEN_JWT, session.consentJwt);
  }
  if (session.disableLoginHintEditing) {
    params.set(PARAM_CODE_DISABLE_LOGIN_HINT_EDITING, session.disableLoginHintEditing ? "true" : "false");
  }

  return `${joinPaths([baseUrl, authorizeEndpoint])}?${params.toString()}`;
};

const getParamValue = (name: string, params: URLSearchParams) => {
  const paramValue = params.get(name);

  if (!paramValue) {
    return null;
  }

  return decodeURIComponent(paramValue);
};

export const matchesEventOrigin = (eventOrigin: string, origin: string) => {
  if (eventOrigin === origin) {
    return true;
  }

  if (origin.endsWith("/") && origin.length > 1) {
    return eventOrigin === origin.substring(0, origin.length - 1);
  }

  return false;
};
