import { SignInClientState } from "../../types";

export class SdkError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class AuthError extends SdkError {
  constructor(public error: string, public errorDescription: string, public errorUri: string, public clientState?: SignInClientState) {
    super(errorDescription || error);
  }
}

export class AuthCancelledError extends SdkError {
  constructor(message: string) {
    super(message);
  }
}
