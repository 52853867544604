import { joinPaths } from "../utils/path";
import { config, getParamGrantTypeValue, getApiDefaultTimeoutMs } from "../../config";
import { fetchRequest } from "../utils/fetch";
import {
  ERROR_CODE_JWT_DECODE,
  ERROR_CODE_OIDC_ID_TOKEN_REQUEST_FAILED,
  ERROR_CODE_NO_ACCESS_TOKEN,
  ERROR_CODE_AUTHORIZE_DEVICE_REQUEST_FAILED,
  ERROR_CODE_DEVICE_TOKEN_REQUEST_FAILED,
} from "../error/codes";
import { AuthError, SdkError } from "../error/errors";
import { createFormBody } from "../utils/fetch";
import { decodeIdTokenJwt } from "../utils/idTokenJwtDecode";
import { AuthSession } from "../internalTypes";
import { OpenPassOptions, OpenPassTokens } from "../../types";
import {
  ApiError,
  OpenPassTokensResponse,
  ClientTelemetryEventType,
  AuthorizeDeviceResponse,
  AuthorizeDeviceRequest,
  DeviceTokenRequest,
  DeviceTokenWithStatusResponse,
} from "./types";
import { version as sdkVersion } from "../../../package.json";
import { HEADER_SDK_NAME, HEADER_SDK_VERSION, SDK_NAME } from "../constants";

/**
 * Holds all methods that call the OpenPass API
 */
export class OpenPassApiClient {
  private options: OpenPassOptions;

  constructor(options: OpenPassOptions) {
    this.options = options;
    this.validateOptions(options);
  }

  public async exchangeAuthCodeForTokens(authCode: string, authSession: AuthSession): Promise<OpenPassTokens> {
    const reqBody = {
      grant_type: getParamGrantTypeValue(),
      client_id: authSession.clientId,
      redirect_uri: authSession.redirectUrl,
      code: authCode,
      code_verifier: authSession.codeVerifier,
    };

    const headers: Record<string, string> = {};
    headers[HEADER_SDK_NAME] = SDK_NAME;
    headers[HEADER_SDK_VERSION] = sdkVersion;
    headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";

    const response = await fetchRequest(this.resolveUri(config.SSO_TOKEN_PATH), {
      method: "POST",
      headers: headers,
      body: createFormBody(reqBody),
      timeout: getApiDefaultTimeoutMs(),
    });

    const responseJson = response.json as OpenPassTokensResponse | ApiError;

    if (this.isErrorResponse(responseJson)) {
      throw new AuthError(
        responseJson.error ?? ERROR_CODE_OIDC_ID_TOKEN_REQUEST_FAILED,
        responseJson.error_description ?? "Error retrieving token",
        responseJson.error_uri ?? "",
        authSession.clientState
      );
    }

    const rawIdToken = responseJson.id_token;
    const idToken = decodeIdTokenJwt(rawIdToken);

    // this should not typically happen, but just in case...
    if (!idToken) {
      throw new AuthError(ERROR_CODE_JWT_DECODE, "Unable to decode jwt", "", authSession.clientState);
    }

    const accessToken = responseJson.access_token;
    const refreshToken = responseJson.refresh_token;

    // this should not typically happen, but just in case...
    if (!accessToken) {
      throw new AuthError(ERROR_CODE_NO_ACCESS_TOKEN, "No access token was returned", "", authSession.clientState);
    }

    return {
      idToken,
      rawIdToken,
      accessToken,
      refreshToken,
      rawAccessToken: accessToken,
      tokenType: responseJson.token_type,
      expiresIn: responseJson.expires_in,
    };
  }

  public async authorizeDevice(clientId: string, loginHint?: string, disableLoginHintEditing?: boolean): Promise<AuthorizeDeviceResponse> {
    var reqBody = {
      scope: "openid",
      client_id: clientId,
    } as AuthorizeDeviceRequest;

    if (loginHint) {
      reqBody.login_hint = loginHint;
    }

    if (disableLoginHintEditing) {
      reqBody.disable_login_hint_editing = disableLoginHintEditing;
    }

    const headers: Record<string, string> = {};
    headers[HEADER_SDK_NAME] = SDK_NAME;
    headers[HEADER_SDK_VERSION] = sdkVersion;
    headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";

    const response = await fetchRequest(this.resolveUri(config.SSO_AUTHORIZE_DEVICE_PATH), {
      method: "POST",
      headers: headers,
      body: createFormBody(reqBody),
      timeout: getApiDefaultTimeoutMs(),
    });

    const responseJson = response.json as AuthorizeDeviceResponse | ApiError;

    if (this.isErrorResponse(responseJson)) {
      throw new AuthError(
        responseJson.error ?? ERROR_CODE_AUTHORIZE_DEVICE_REQUEST_FAILED,
        responseJson.error_description ?? "Error authorizing device",
        responseJson.error_uri ?? ""
      );
    }

    return responseJson;
  }

  public async deviceToken(clientId: string, deviceCode: string): Promise<DeviceTokenWithStatusResponse> {
    var reqBody = {
      client_id: clientId,
      grant_type: "urn:ietf:params:oauth:grant-type:device_code",
      device_code: deviceCode,
    } as DeviceTokenRequest;

    const headers: Record<string, string> = {};
    headers[HEADER_SDK_NAME] = SDK_NAME;
    headers[HEADER_SDK_VERSION] = sdkVersion;
    headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";

    const response = await fetchRequest(this.resolveUri(config.SSO_DEVICE_TOKEN_PATH), {
      method: "POST",
      headers: headers,
      body: createFormBody(reqBody),
      timeout: getApiDefaultTimeoutMs(),
    });

    const responseJson = response.json as OpenPassTokensResponse | ApiError;

    if (this.isErrorResponse(responseJson)) {
      if (responseJson.error === "authorization_pending") {
        return {
          status: "authorization_pending",
        };
      } else if (responseJson.error === "slow_down") {
        return {
          status: "slow_down",
        };
      } else {
        throw new AuthError(
          responseJson.error ?? ERROR_CODE_DEVICE_TOKEN_REQUEST_FAILED,
          responseJson.error_description ?? "Error getting device token",
          responseJson.error_uri ?? ""
        );
      }
    }

    return {
      status: "ok",
      tokensResponse: responseJson as OpenPassTokensResponse,
    };
  }

  public async sendTelemetryEvent(eventType: ClientTelemetryEventType): Promise<void> {
    const headers: Record<string, string> = {};
    headers[HEADER_SDK_NAME] = SDK_NAME;
    headers[HEADER_SDK_VERSION] = sdkVersion;
    headers["Content-Type"] = "application/json";

    const payload = {
      client_id: this.options.clientId,
      event_type: eventType,
    };

    await fetchRequest(this.resolveUri(config.SSO_TELEMETRY_EVENT_PATH), {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
  }

  private resolveUri(uri: string) {
    const baseUri = this.options.baseUrl || config.SSO_BASE_URL;
    return joinPaths([baseUri, uri]);
  }

  private isErrorResponse(
    response: OpenPassTokensResponse | AuthorizeDeviceResponse | OpenPassTokensResponse | ApiError
  ): response is ApiError {
    return (response as ApiError).error !== undefined;
  }

  private validateOptions(options: OpenPassOptions) {
    if (!options.clientId) {
      throw new SdkError("Error clientId is invalid. Please use a valid clientId");
    }
  }
}
