export function executeFunctionByName(functionName: string, context: any, functionArgs: any[]) {
  const namespaces = functionName.split(".");
  const func = namespaces.pop();

  if (func === undefined) {
    throw Error("Function name not provided");
  }

  for (let i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }

  const toExecute = context[func];

  if (toExecute === undefined) {
    throw Error(`Function '${func}' not found`);
  }

  return toExecute.apply(context, functionArgs);
}
